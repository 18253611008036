import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../components/pagination";
import { orderStatus, getCDNLink } from '../../utils/config';
import { ArrowRightIcon, PureFileIcon } from "../../assets/icons";
import moment from "moment";
import ClearFilters from "../../components/table/clear-filters";
import { useTranslation } from "react-i18next";
import { EStatuses, Subscription } from "../../types/types";
import { Tooltip } from "@sellix/shared/components";
import { GATEWAYS_OPTIONS } from "@sellix/shared/configs";


const MobileProducts = ({ data, clearFilters }: { data: Subscription[], clearFilters?: () => void }) => {

  const SIZE = 3;
  const { t } = useTranslation('translation', { keyPrefix: "customer.subscriptions" });
  const subscriptionTranslate = useTranslation("translation", { keyPrefix: 'customer.subscriptions.filters.statuses' });

  const [currentPage, setCurrentPage] = useState<number | "...">(1);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (+currentPage - 1) * SIZE;
    const lastPageIndex = firstPageIndex + SIZE;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, SIZE]);

  if(!data.length) {
    if(clearFilters) {
      return <ClearFilters clearFilters={clearFilters} />
    }
    return null;
  }

  return (
    <div>
      {currentTableData.map((item, key) => {

        const { id, is_evm_approved, status, gateway, product_title, created_at, current_period_end, cloudflare_image_id } = item;

        const to = `/subscriptions/${id}`

        return <div className="table-mobile-card" key={key}>

          <Link to={to} className="table-mobile-card-header">
            <div className="flex">
              <div className="table-mobile-card-header-icon">
                {cloudflare_image_id ? <img src={getCDNLink({ id: cloudflare_image_id })} alt=""/> : <PureFileIcon />}
              </div>

              <div className="table-mobile-card-header-main">
                <Link to={to}>{id || product_title}</Link>
                <div>{moment(created_at * 1000).format('HH:mm DD MMM')}</div>
              </div>
            </div>
            <div style={{ color: "var(--black5)" }}>
              <ArrowRightIcon/>
            </div>
          </Link>

          <div className="table-mobile-card-body">
            <div className="table-mobile-card-body-list">
              {
                (gateway && GATEWAYS_OPTIONS[gateway].imgUrl) && <div>
                  <div>{t('table.columns.gateway')}</div>
                  <div>{
                    (GATEWAYS_OPTIONS[gateway].imgUrl) ?
                      <div className="flex items-center justify-start"><img className="mr-2" src={GATEWAYS_OPTIONS[gateway].imgUrl} width={15} height={15} alt="" />{GATEWAYS_OPTIONS[gateway].title}</div>
                      :
                      <div className="flex items-center justify-start">{t('table.row.notSelected')}</div>
                  }</div>
                </div>
              }

              <div>
                <div>{t('table.columns.status')}</div>
                <div className="flex flex-wrap gap-2 items-center">
                  <div className={`badge badge-${(status || "").toLowerCase()}`}>
                    {(orderStatus(subscriptionTranslate.t)[status as keyof typeof EStatuses] || '').toLowerCase()}
                  </div>
                  {is_evm_approved ?
                    <Tooltip place="left" name={`approved-${id}`} tooltipText={`You have allowed us to automatically use your ${gateway} funds to pay for invoices on recurring bills.`}>
                      <div className="badge badge-confirmation">
                          Allowance
                      </div>
                    </Tooltip>
                    : null}
                </div>
              </div>

              <div>
                <div>{t('table.columns.end')}</div>
                <div>{moment(current_period_end * 1000).format('DD MMM YYYY (HH:mm)')}</div>
              </div>
            </div>
          </div>

        </div>
      })}

      <Pagination
        currentPage={+currentPage}
        totalCount={data.length}
        pageSize={SIZE}
        onPageChange={setCurrentPage}
      />

    </div>
  );
};

export default MobileProducts;
