import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin, Button, toastAlert } from "@sellix/shared/components";
import { editRequestFundsNote } from "./actions.tsx";
import { useCustomerContext } from "../../../provider/CustomerContext.tsx";
import Modal from "../../../components/modal";
import { FundsRequest } from "../../../types/types.ts";

import "./index.css";


const EditNoteModal = ({ isOpen, close, activeFundsRequest }: { isOpen: boolean, close: () => void, activeFundsRequest: FundsRequest | null }) => {

  const { customerInfo, shopInfo, refetchCustomerInfo } = useCustomerContext();
  const { t } = useTranslation("translation", { keyPrefix: "customer.balances.modals" });

  const [loading, setLoading] = useState(false);
  const [customerNote, setCustomerNote] = useState<string>("");
  const currentMode = localStorage.getItem("theme") || "light";

  useEffect(() => {
    setCustomerNote(activeFundsRequest?.customer_note || "");
  }, [activeFundsRequest?.customer_note]);

  const submit = useCallback(async() => {

    try {
      setLoading(true);

      const res = await editRequestFundsNote({ // @ts-ignore
        request_id: activeFundsRequest.id,
        customer_note: customerNote, // @ts-ignore
        customer_id: customerInfo.id, // @ts-ignore
        shop_id: shopInfo.shop.id
      });

      if(res.status === 200) {
        try {
          localStorage.setItem(`withdrawal-note-${shopInfo?.shop.id}`, customerNote);
        } catch (error) {
          console.error(error);
        }
        setCustomerNote("");
        refetchCustomerInfo();
        close();
        toastAlert("success", res.message || "");
      } else {
        throw res;
      }

    } catch (error: any) {
      toastAlert("error", (error ? (error.error || error.message) : "") || "Server Error!");
    } finally {
      setLoading(false);
    }
  }, [customerInfo, shopInfo, customerNote, activeFundsRequest]);

  if(!isOpen || !activeFundsRequest) {
    return null;
  }

  return <>

    <Modal open={isOpen} className="sellix-top-up-modal" closeModal={close}>
      <div className="sm:p-8 sm:pb-6 p-4 pb-3 border-b-0">
        <div className="new-modal-title">
          {t("edit.title")}
        </div>
        <div className="new-modal-description">
          {t("edit.subtitle")}
        </div>
      </div>
      <form className="p-4 pt-0 pb-0 sm:pt-0 sm:pb-0 sm:p-8" onSubmit={(e) => e.preventDefault()}>

        <div className="request-withdraw-modal-note">
          <label htmlFor="" className="sellix-label">{t("edit.note")}</label>
          <textarea autoFocus
            placeholder="Bitcoin: 1333P1eP5QGefi2D333fTL333mv7Di333a"
            className="sellix-input textarea !rounded-r-[0px]" value={customerNote}
            onChange={(e) => setCustomerNote(e.target.value)}
          />
        </div>

      </form>

      <div className="justify-between border-t-0 p-4 pt-3 sm:p-8 sm:pt-6">
        <Button className="w-full" size="extra-large" disabled={loading || (!customerNote)} onClick={submit}>
          {loading ? <Spin variant={currentMode === "dark" ? "black" : "white"}/> : t("edit.button")}
        </Button>
      </div>
    </Modal>

  </>
}

export default EditNoteModal;
