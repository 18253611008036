import React from 'react';

import { CURRENCY_OPTIONS, Currency, CurrencyInfo } from "@sellix/shared/configs";
import { Price } from "@sellix/shared/components";
import { RewardPayload } from '../../types/types.ts';

interface RewardValueProps {
  reward?: RewardPayload
}

const RewardValue: React.FC<RewardValueProps> = ({ reward }) => {
  let value, title;
  const rewardInfo = reward?.reward_info;

  if (!rewardInfo) {
    return null;
  }

  const isLicense = rewardInfo.product?.licensing_enabled;
  const isSubscription = rewardInfo.product?.type === 'SUBSCRIPTION';
  const rewardCurrency = CURRENCY_OPTIONS[rewardInfo.currency as Currency] as CurrencyInfo;

  switch (reward.action) {
    case 'SEND_COUPON_CODE':
      title = 'Coupon';
      value = <u>{rewardInfo.coupon?.code}</u>;
      break;
    case 'SEND_PRODUCT':
      title = isSubscription
        ? 'Subscription'
        : (isLicense ? 'License' : 'Product');
      value = <u>{rewardInfo.product?.title}</u>;
      break;
    case 'TOP_UP_BALANCE_AMOUNT':
      title = 'Balance';
      value = <Price currency={rewardCurrency} price={rewardInfo.total} />
      break;
    case 'TOP_UP_BALANCE_PERCENT':
      title = 'Balance'
      value = `${reward.action_value}%`;
      break;
  }

  return <>
    <span style={{ paddingRight: '0.25rem' }}>
      {title}:
    </span>
    {value}
  </>;
};

export default RewardValue;