import customerApi from '../utils/customer-api';
import api from '../utils/api';
import { CustomerInfo, CustomerTheme, Response } from "../types/types";
import { ShopInfo } from "../types/types";

export const getCustomerInfo = async(shopId: number): Promise<Response<CustomerInfo>> => {
  try {
    return await customerApi.get<never, Response<CustomerInfo>>(`shop/${shopId}/customers/dashboard`);
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export const getCustomerTheme = async(shopId: number): Promise<Response<CustomerTheme>> => {
  try {
    return await customerApi.get<never, Response<CustomerTheme>>(`/internal/${shopId}/themes/schema`, {
      params: {
        include_files: false,
        include_menus: false,
        include_page: false,
        is_active: true
      }
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export const getGeneralShopInfo = async(name: string): Promise<Response<ShopInfo>> => {
  try {
    return await api.get<never, Response<ShopInfo>>(`/shops/domain/${name}`);
  } catch (e) {
    console.error(e);
    throw e;
  }

}


export const getCustomerProfile = async() => {
  const res = await customerApi.get(`/self_customer`);
  if (res.status === 200) {
    return res
  } else {
    console.error(res);
    throw res
  }
}
