import React, { useEffect, useMemo, useState } from "react";
import Pagination from "../../../components/pagination";
import { random } from "../../../utils/config.tsx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { PaymentMethod } from "../../../types/types";
import { TrashIcon } from "@sellix/shared/components";
import { ICONS, TITLES } from "./constants.ts";

interface MobilePlaceholderProps {}

export const MobilePlaceholder: React.FC<MobilePlaceholderProps> = () => {
  return (
    <div>
      {[1, 2, 3].map((key: number) => (
        <div className="table-mobile-card" key={key}>
          <div className="table-mobile-card-body">
            <div className="table-mobile-card-body-list">
              <div>
                <div>
                  <div className="skeleton skeleton2 small" style={{ width: random(50, 120) }}/>
                </div>
                <div>
                  <div className="skeleton skeleton2 medium" style={{ width: random(100, 180) }}/>
                </div>
              </div>
              <div>
                <div>
                  <div className="skeleton skeleton2 small" style={{ width: random(50, 120) }}/>
                </div>
                <div>
                  <div className="skeleton skeleton2 medium" style={{ width: random(100, 180) }}/>
                </div>
              </div>

              <div>
                <div>
                  <div className="skeleton skeleton2 small" style={{ width: random(50, 120) }}/>
                </div>
                <div>
                  <div className="skeleton skeleton2 medium" style={{ width: random(100, 180) }}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

interface ActionsProps {
  item: PaymentMethod
  deletePaymentMethod: (id: number) => void
}

const Actions = ({ item, deletePaymentMethod }: ActionsProps) => {
  return <div className="payment-method-delete-btn" onClick={() => deletePaymentMethod(item.id)}>
    <TrashIcon/>
  </div>
};

interface Props {
  data: PaymentMethod[]
  deletePaymentMethod: (id: number) => void
}

const MobilePaymentMethods = ({ data, deletePaymentMethod }: Props) => {

  const { t } = useTranslation("translation", { keyPrefix: "customer.paymentMethods" });

  const SIZE = 3;

  const [currentPage, setCurrentPage] = useState<number | "...">(1);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (+currentPage - 1) * SIZE;
    const lastPageIndex = firstPageIndex + SIZE;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, SIZE]);

  useEffect(() => {
    if (!currentTableData.length && !Number.isNaN(currentPage) && +currentPage > 0) {
      setCurrentPage((prevValue) => +prevValue - 1)
    }
  }, [currentPage, currentTableData]);

  return (
    <div>
      {currentTableData.map((item, key) => {
        const Icon = ICONS[item.gateway as keyof typeof ICONS];
        const title = TITLES[item.gateway as keyof typeof TITLES];
        const isActive = item.status === "ACTIVE";
        const isInactive = item.status === "INACTIVE";

        return <div className="table-mobile-card" key={key}>
          <div className="table-mobile-card-header">
            <div className="table-mobile-card-header-icon">
              <Icon />
            </div>

            <div className="table-mobile-card-header-main">
              {title}
            </div>
          </div>

          <div className="table-mobile-card-body">
            <div className="table-mobile-card-body-list">
              <div>
                <div>{t("table.columns.title")}</div>
                <div>{item.title}</div>
              </div>
              <div>
                <div>{t("table.columns.status")}</div>
                <div
                  className={`badge ${isActive && "badge-success"} ${isInactive && "badge-failed"}`}>
                  {isActive && t("status.active")}
                  {isInactive && t("status.inactive")}
                </div>
              </div>

              <div>
                <div>{t("table.columns.lastConfirmDate")}</div>
                <div>
                  {item.last_confirm_date
                    ? moment(item.last_confirm_date, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY (HH:mm)")
                    : null}
                </div>
              </div>

              <div>
                <div></div>
                <div>
                  <Actions item={item} deletePaymentMethod={deletePaymentMethod}/>
                </div>
              </div>

            </div>
          </div>
        </div>
      })}

      <Pagination
        currentPage={+currentPage}
        totalCount={data.length}
        pageSize={SIZE}
        onPageChange={setCurrentPage}
      />

    </div>
  );
};

export default MobilePaymentMethods;