import React from "react";
import Table from "../../components/table";
import Empty from "../../components/empty";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import config, { orderStatus, random } from "../../utils/config";
import { FIAT_SYMBOLS, GATEWAYS_OPTIONS } from "@sellix/shared/configs";
import { ArrowRightIcon, CartIcon, SubsIcon, ProductIcon } from "../../assets/icons";
import useMobile from "../../hooks/useMobile";
import MobileProducts from "./mobile-products";
import MobilePlaceholder from "../subscriptions/mobile-placeholder";
import { useTranslation } from "react-i18next";
import { InvoiceInfo } from "../../types/types";
import { CellContext, ColumnDef, Row } from "@tanstack/react-table";
import { Tooltip } from "@sellix/shared/components";

import "./index.css";

interface InvoicesTableProps {
	isEmpty: boolean
	orders: InvoiceInfo[]
	loading: boolean
	clearFilters?: () => void
}

const InvoicesTable = ({ isEmpty, orders, loading, clearFilters }: InvoicesTableProps) => {

  const isMobile = useMobile();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: "customer.invoices" });
  const invoiceTranslate = useTranslation("translation", { keyPrefix: 'customer.invoices.filters.statuses' });

  const renderStatus = ({ row }: CellContext<InvoiceInfo, unknown>) => {

    const status = orderStatus(invoiceTranslate.t)[row.original.status || 0];

    return (
      <div className="flex justify-center gap-2">
        {row.original.status === "VOIDED" && row.original.void_details === "PRODUCT_SOLD_OUT" && <span className="badge badge-cancelled">{t('table.row.soldOut')}</span>}
        {row.original.status === "VOIDED" && row.original.void_details === "CART_PRODUCTS_SOLD_OUT" && <span className="badge badge-cancelled">{t('table.row.cartSoldOut')}</span>}
        {row.original.status === "COMPLETED" && row.original.status_details === "CART_PARTIAL_OUT_OF_STOCK" && <span className="badge badge-cancelled">{t('table.row.partialOut')}</span>}
        <div className={`badge badge-${(row.original.status || "").toLowerCase()}`}>
          {status}
        </div>

        {row.original.is_evm_approved ?
          <Tooltip name={`approved-${row.original.uniqid}`} tooltipText={`You have allowed us to automatically use your ${row.original.gateway} funds to pay for invoices on recurring bills.`}>
            <div className="badge badge-confirmation">
                Allowance
            </div>
          </Tooltip>
          : null}
      </div>
    )
  };

  const renderValue = ({ row }: CellContext<InvoiceInfo, unknown>) => {

    const isFree = +row.original.total_display === 0;

    if(isFree) {
      return <div className="flex flex-col text-nowrap">
        <span className="flex" />
        <span className="flex">
          {t('table.row.free')}
        </span>
      </div>
    }

    return <div className="flex flex-col text-nowrap">
      <div className="flex items-center mb-1">{FIAT_SYMBOLS[row.original.currency]}{row.original.total_display}</div>
      <div className="invoice-list-id">
        {row.original.crypto_amount}
      </div>
    </div>
  };

  const renderGateway = ({ row }: CellContext<InvoiceInfo, unknown>) => {

    const isFree = +row.original.total_display === 0;

    if(isFree || !row.original.gateway) {
      return "-"
    }

    return <div className="flex items-center">
      <img
        className="mr-2"
        style={{ width: 15, height: 15 }}
        src={GATEWAYS_OPTIONS[row.original.gateway]?.imgUrl} alt=""
      />
      {GATEWAYS_OPTIONS[row.original.gateway]?.title}
    </div>
  };

  const renderTime = ({ row }: CellContext<InvoiceInfo, unknown>) => moment(row.original.created_at * 1000).format('HH:mm DD MMM YYYY')

  const flag = (country: string) => {
    if(country && country.length > 1) {
      return `${config.CDN_FLAGS_URL}${(country || "").toLowerCase()}.svg`
    } else {
      return ""
    }
  };

  const renderInfo = ({ row }: CellContext<InvoiceInfo, unknown>) => (
    <div className="flex">

      <div className="invoice-list-icon">
        {row.original.type === 'SHOPPING_CART' ?
          <CartIcon /> :
          row.original.type === 'PRODUCT_SUBSCRIPTION' ?
            <SubsIcon /> :
            <ProductIcon />
        }
      </div>
      <div>
        <span className="flex mb-1">
          {row.original.customer_email ?
            row.original.customer_email :
            row.original.type === 'SHOPPING_CART' ?
              t('table.row.shoppingCart') :
              row.original.product_title ?
                row.original.product_title :
                t('table.row.noProduct')
          }
          {row.original.country && row.original.country !== 'N/A' ? <img src={flag(row.original.country)} className="flag-icon ml-2" alt={row.original.location} style={{ width: 20, height: 14, borderRadius: 2 }} /> : null}
        </span>
        <span className="invoice-list-id">
          {row.original.uniqid}
        </span>
      </div>
    </div>
  )

  const columns: ColumnDef<InvoiceInfo>[] = [{
    accessorKey: 'uniqid',
    header: t('table.columns.info') as string,
    cell: renderInfo
  }, {
    accessorKey: 'gateway',
    header: t('table.columns.gateway') as string,
    cell: renderGateway
  }, {
    accessorKey: 'total_display',
    header: t('table.columns.value') as string,
    cell: renderValue,
    sortingFn: (rowA: Row<InvoiceInfo>, rowB: Row<InvoiceInfo>): number => {
      if(parseInt(rowB.original.total_display) > parseInt(rowA.original.total_display)) {
        return 1
      }
      if(parseInt(rowA.original.total_display) > parseInt(rowB.original.total_display)) {
        return -1
      }
      return 0
    }
  }, {
    accessorKey: 'status',
    header: t('table.columns.status') as string,
    cell: renderStatus
  }, {
    accessorKey: 'created_at',
    header: t('table.columns.update') as string,
    cell: renderTime
  }, {
    accessorKey: 'id',
    enableSorting: false,
    header: '',
    cell: () => <ArrowRightIcon />
  }];

  const placeholder = [{
    accessorKey: 'uniqid',
    header: t('table.columns.info'),
    cell: () => (
      <div className="flex">
        <div className="invoice-list-icon">
          <ProductIcon />
        </div>
        <div>
          <span className="flex mb-1"><div className="mb-2 skeleton skeleton2 small" style={{ width: random(150, 250) }} /></span>
          <span className="invoice-list-id"><div className="skeleton skeleton7 small" style={{ width: random(120) }} /></span>
        </div>
      </div>
    )
  }, {
    accessorKey: 'gateway',
    header: t('table.columns.gateway'),
    cell: () => <div className="flex items-center">
      <div className="skeleton skeleton2 mr-2" style={{ borderRadius: ".25rem", width: 15, height: 15 }} /> <div className="skeleton skeleton3" style={{ width: random(40, 80) }} />
    </div>
  }, {
    accessorKey: 'total_display',
    header: t('table.columns.value'),
    cell: () => <div className="flex flex-col">
      <div className="skeleton skeleton2 mb-1 small" style={{ width: random(50, 100) }} />
      <div className="skeleton skeleton3 flex small" style={{ width: random(70, 120) }} />
    </div>
  }, {
    accessorKey: 'status',
    header: t('table.columns.status'),
    cell: () => <div className="skeleton skeleton7" style={{ width: random(40, 80) }} />
  }, {
    accessorKey: 'created_at',
    header: t('table.columns.update'),
    cell: () => <div className="skeleton skeleton2" style={{ width: random(50, 100) }} />
  }, {
    accessorKey: 'id',
    enableSorting: false,
    header: '',
    cell: () => <ArrowRightIcon />
  }];

  if(isEmpty && !loading) {
    return <Empty isInvoices />
  }

  if(loading) {
    if(isMobile) {
      return <MobilePlaceholder data={[{}, {}, {}]} />
    } else {
      return <Table data={[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]} isPlaceholder columns={placeholder} pageSize={10} />
    }
  } else {
    if(isMobile) {
      return <MobileProducts clearFilters={clearFilters} data={orders} />
    } else {
      return <Table
        cursorPointer
        clearFilters={clearFilters}
        pageSize={10}
        data={ orders }
        columns={ columns }
        onClick={(row, e) => {
          if (e.target.tagName !== 'A') {
            navigate(`/invoices/${row.uniqid}`);
          }
        }}
      />
    }
  }
}


export default InvoicesTable;
