import { GiftCardsWrapper, Response } from '../../../types/types.ts';
import customerApi from '../../../utils/customer-api.ts';
import { formData } from "../../../utils/config.tsx";

export const getGiftCards = async(): Promise<Response<GiftCardsWrapper>> => {
  const res = await customerApi.get<never, Response<GiftCardsWrapper>>(
    `customer/gift_cards`,
  );

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}

export const redeemGiftCard = async(code: string): Promise<Response<undefined>> => {
  const res = await customerApi.post<never, Response<undefined>>(
    `customer/gift_cards/redeem`,
    formData({ code }),
  );

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}
