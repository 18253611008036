import React, { useState, useEffect } from "react";
import { EthereumWallets, TronLinkWallets } from "@sellix/shared/wallets";
import { toastAlert } from "@sellix/shared/components";
import { evmSpenders } from "../../actions";
import { EBlockchain } from "../../../../types/types";
import { removeEVMApprove } from "../../../invoices/details/actions.ts";


const EVMWallets = ({ initializeData, bill, showCancelEVMModal }: any) => {

  const { coin: gateway, blockchain } = bill.approved_address;

  const removeEVM = () => {
    removeEVMApprove(bill.invoices[0].uniqid as string)
      .then(res => {
        initializeData();
        showCancelEVMModal(false);
      })
      .catch(err => {
        console.log(err)
      })
  }


  const isTron = gateway === "TRON" || gateway === "USDT" && blockchain === "TRC20" || gateway === "PLZ" && blockchain === "TRC20";
  const isEthereum = ["ETHEREUM", "BINANCE_COIN", "POLYGON", "USDT", "USDC", "USDC_NATIVE", "PLZ", "SHIB", "DAI", "PEPE", "APE", "WETH"].includes(gateway) && !(gateway === "USDT" && blockchain === "TRC20") && !(gateway === "PLZ" && blockchain === "TRC20")
  const currentMode = localStorage.getItem("theme") || "light";

  const [EVMSpender, setEVMSpender] = useState(null);

  const getEVMSender = (chain: EBlockchain) => {
    evmSpenders()
      .then(res => {
        setEVMSpender(res.data.spenders[chain]);
      })
      .catch(err => {
        toastAlert('error', err.error || err.message);
      });
  }

  useEffect(() => {
    getEVMSender(blockchain)
  }, [blockchain]);

  const evm = {
    isAllowed: true,
    spender: EVMSpender,
    setStatus: () => {},
    status: "APPROVED" as "PENDING" | "APPROVED" | "SAVED" | "PAID",
    onSave: () => {},
    isReset: true,
    onReset: removeEVM,
  };

  if(isEthereum) {
    return <EthereumWallets
      invoice={{
        gateway,
        theme: currentMode,
        blockchain,
        interval: "YEAR",
        crypto_amount: 0
      }}
      evm={evm}
    />
  }

  if(isTron) {
    return <TronLinkWallets
      invoice={{
        gateway,
        blockchain,
        theme: currentMode,
        interval: "YEAR",
        crypto_amount: 0
      }}
      onInsightsInsert={() => {}}
      evm={evm}
    />
  }

}

export default EVMWallets;

