import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FIAT_SYMBOLS } from "@sellix/shared/configs";
import { Button } from "@sellix/shared/components";
import { useCustomerContext } from "../../provider/CustomerContext";
import { Tooltip } from "@sellix/shared/components";
import RequestWithdrawTransferModal from "./modals/request-withdraw.tsx";
import InvoicesModal from "./modals/invoices.tsx";
import EditNoteModal from "./modals/edit-note.tsx";
import { TopUpBalanceIcon, WithdrawalsIcon } from "../profile/overview";
import TopUpModal from "../profile/overview/top-up.tsx";
import { FundsRequest, RevenueInvoice } from "../../types/types.ts";

import "./index.css";


const BalancesOverview = () => {

  const [topUpModalOpen, setTopUpModalOpen] = useState(false);
  const [requestWithdrawOpen, setRequestWithdrawModal] = useState<"WITHDRAWAL" | "TRANSFER" | null>(null);
  const [editNoteModalOpen, setEditNoteModalOpen] = useState<boolean>(false);
  const [invoicesModalOpen, setInvoicesModalOpen] = useState<boolean>(false);

  const [activeRevenueInvoice, setActiveRevenueInvoice] = useState<RevenueInvoice | null>(null);
  const [activeFundsRequest, setActiveFundsRequest] = useState<FundsRequest | null>(null);

  const { shopInfo, customerInfo, refetchCustomerInfo, revenueInvoices, customerBalanceHistory } = useCustomerContext();
  const { t } = useTranslation("translation", { keyPrefix: "customer.balances" });

  if(!customerInfo) {
    return null
  }

  const totalEarned = <>{FIAT_SYMBOLS[customerInfo.customer.total_earned_currency as keyof typeof FIAT_SYMBOLS]}{(+(customerInfo.customer.total_earned || 0)).toFixed(2)}</>;
  const affiliatePercentage = customerInfo.shop.affiliate_revenue_active ? `${+customerInfo.customer.affiliate_revenue_percent || customerInfo.shop.affiliate_revenue_percent}%` : " Currently Disabled";
  const discountPercentage = customerInfo.shop.affiliate_revenue_active && customerInfo.shop.affiliate_customer_discount_active ? `${+customerInfo.customer.affiliate_customer_discount_percent || +customerInfo.shop.affiliate_customer_discount_percent}%` : " Currently Disabled";
  const withdrawalBalance = <>{FIAT_SYMBOLS[customerInfo.affiliate_revenue_currency as keyof typeof FIAT_SYMBOLS]}{(+(customerInfo.affiliate_revenue || 0)).toFixed(2)}</>;
  const customerBalance = <>{FIAT_SYMBOLS[customerInfo.customer_balance_currency as keyof typeof FIAT_SYMBOLS]}{(+(customerInfo.customer_balance || 0)).toFixed(2)}</>;

  const lockedBalance = customerBalanceHistory.reduce((acc, cur): number => {
    if(cur.type === "BALANCE_USED" && !cur.is_added) {
      return acc + +cur.amount;
    } else {
      return acc;
    }
  }, 0) || null;

  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const todayTimestamp = now.getTime();

  const today = revenueInvoices.filter(({ created_at }: any) => {
    const invoiceDate = new Date(created_at * 1000).getTime();
    return invoiceDate >= todayTimestamp && invoiceDate < todayTimestamp + 86400000;
  });

  const todayAmount = today.reduce((acc, { amount }: any) => {
    return acc + +amount
  }, 0).toFixed(2)

  const totalReferrals = revenueInvoices.length;

  const idleRevenueInvoices = revenueInvoices.filter(({ status }) => status === "NO_ACTION");

  return <>

    <InvoicesModal
      activeFundsRequest={activeFundsRequest}
      revenueInvoices={revenueInvoices}
      isOpen={invoicesModalOpen}
      close={() => {
        setInvoicesModalOpen(false);
      }}
    />

    <RequestWithdrawTransferModal
      revenueInvoices={idleRevenueInvoices}
      isOpen={!!requestWithdrawOpen}
      isWithdrawal={requestWithdrawOpen === "WITHDRAWAL"}
      setWithdrawRequest={setRequestWithdrawModal}
      activeRevenueInvoice={activeRevenueInvoice}
      close={() => {
        setActiveRevenueInvoice(null);
        setRequestWithdrawModal(null);
      }}
    />

    <EditNoteModal
      isOpen={editNoteModalOpen}
      activeFundsRequest={activeFundsRequest}
      close={() => setEditNoteModalOpen(false)}
    />


    <div className="dashboard-info-cards">

      <div className="dashboard-info-card">
        <div className="dashboard-info-card-title">{t("overview.titles.totalRevenue")}</div>
        <div className="dashboard-info-card-info">
          {totalEarned}
          {today.length ? <div className="dashboard-info-card-info-add"><span>+${todayAmount}</span> today</div> : null}
        </div>
      </div>

      <div className="dashboard-info-card">
        <div className="dashboard-info-card-title">{t("overview.titles.totalReferrals")}</div>
        <div className="dashboard-info-card-info">
          {totalReferrals}
          {today.length ? <div className="dashboard-info-card-info-add"><span>+{today.length}</span> today</div> : null}
        </div>
      </div>

      <div className="dashboard-info-card">
        <div className="dashboard-info-card-title">{t("overview.titles.revenuePercentage")}</div>
        <div className="dashboard-info-card-info">{affiliatePercentage}</div>
      </div>

      <div className="dashboard-info-card">
        <div className="dashboard-info-card-title">{t("overview.titles.referralDiscountPercentage")}</div>
        <div className="dashboard-info-card-info">
          {discountPercentage}
        </div>
      </div>

      <div className="dashboard-info-card flex flex-wrap justify-between gap-2">
        <div>
          <div className="dashboard-info-card-title">{t("overview.titles.affiliateBalance")}</div>
          <div className="dashboard-info-card-info">{withdrawalBalance}</div>
        </div>

        {idleRevenueInvoices.length ? <div className="dashboard-info-card-buttons gap-2 flex flex-wrap">
          <Tooltip noArrow tooltipText={t("overview.tooltips.requestFundsWithdrawal")} name="withdraw-button">
            <Button variant="secondary" onClick={() => {
              setRequestWithdrawModal("WITHDRAWAL");
            }}><WithdrawalsIcon/>&nbsp;&nbsp;&nbsp;{t("overview.buttons.withdrawFunds")}</Button>
          </Tooltip>
          <Tooltip noArrow tooltipText={t("overview.tooltips.transferFunds")} name="transfer-button">
            <Button variant="secondary" onClick={() => {
              setRequestWithdrawModal("TRANSFER")
            }}><WithdrawalsIcon/>&nbsp;&nbsp;&nbsp;{t("overview.buttons.transferFunds")}</Button>
          </Tooltip>
          <Tooltip noArrow tooltipText="Refresh" name="transfer-button">
            <Button variant="secondary" onClick={() => {
              refetchCustomerInfo(true)
            }} square iconName="refresh" />
          </Tooltip>
        </div> : null}
      </div>

      <div className="dashboard-info-card flex flex-wrap justify-between gap-2">
        <div>
          <div className="dashboard-info-card-title">
            {t("overview.titles.customerBalance")}
          </div>
          <div className="dashboard-info-card-info">
            {customerBalance}
            {lockedBalance ? <div className="dashboard-info-card-info-add">{t("overview.locked")}: <span>${lockedBalance.toFixed(2)}</span></div> : null}
          </div>
        </div>

        <div className="dashboard-info-card-buttons gap-2 flex flex-wrap">
          {shopInfo?.shop.enable_top_up_balance ?
            <Button variant="secondary" onClick={() => {
              setTopUpModalOpen(true)
            }}>
              <TopUpBalanceIcon/>&nbsp;&nbsp;&nbsp;{t("overview.topUpBalance")}
            </Button> : null}
        </div>
      </div>

      <div className="dashboard-info-card w-full cursor-pointer hidden">
        <div className="dashboard-info-card-title w-auto flex items-center gap-1">
          {t("overview.titles.referralLink")}
          <Tooltip onlyCopyIcon noArrow tooltipText="Copy referral link" copiedText={`https://${window.location.host}?rcid=${customerInfo.id}`} name="referral-link"/>
        </div>
        <div className="dashboard-info-card-info">{customerInfo.id}</div>
      </div>
    </div>

    <TopUpModal isOpen={topUpModalOpen} close={() => setTopUpModalOpen(false)} />

  </>
}

export default BalancesOverview;
