import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CURRENCY_OPTIONS } from "@sellix/shared/configs";
import Modal from "../../../components/modal";
import { FundsRequest, RevenueInvoice } from "../../../types/types.ts";

import "./index.css";


const InvoicesModal = ({ isOpen, close, revenueInvoices, activeFundsRequest }: { isOpen: boolean, close: () => void, revenueInvoices: RevenueInvoice[], activeFundsRequest: FundsRequest | null }) => {

  const { t } = useTranslation("translation", { keyPrefix: "customer.balances.modals" });

  const [totalList, setTotal] = useState<string[]>([]);

  if(!isOpen || !activeFundsRequest) {
    return null;
  }

  const setTotalList = (invoice: any) => {
    if(totalList.find(({ invoice_id }: any) => invoice_id === invoice.invoice_id)) {
      setTotal(totalList.filter(({ invoice_id }: any) => invoice_id !== invoice.invoice_id));
    } else {
      setTotal([...totalList, invoice]);
    }
  }

  const invoices = revenueInvoices.filter(({ request_id }: any) => activeFundsRequest.id == request_id);

  /* eslint-disable @typescript-eslint/no-unnecessary-condition */
  const groupedInvoices = invoices.reduce<Record<string, RevenueInvoice[]>>((acc, invoice) => {
    const message = invoice.message || "";
    if (!acc[message]) {
      acc[message] = [];
    }
    acc[message].push(invoice);

    return acc;
  }, {});
  /* eslint-enable @typescript-eslint/no-unnecessary-condition */

  const isWithdrawal = activeFundsRequest.type === "WITHDRAWAL";

  return <>

    <Modal open={isOpen} className="sellix-top-up-modal" closeModal={close}>
      <div className="sm:p-8 sm:pb-6 p-4 pb-3 border-b-0">
        <div className="new-modal-title mb-0">
          {isWithdrawal ? t("request.withdrawal") : t("request.transfer")} {activeFundsRequest.id}
        </div>
      </div>
      <form className="p-4 pt-0 pb-0 sm:pt-0 sm:pb-0 sm:p-8" onSubmit={(e) => e.preventDefault()}>

        <div className="request-withdraw-modal-list pt-0 mt-0 pb-2">
          <div className="flex request-withdraw-modal-list-header pt-4 text-sm">
            <div className="flex-[4]">{t("invoice")}</div>
            <div className="flex-[2]">{t("amount")}</div>
            <div className="flex-[2]"><span>{t("status")}</span></div>
          </div>

          {/* style={{ background: key % 2 === 0 ? "var(--black8)" : "" }}*/}
          <div className="request-withdraw-modal-list-body mb-0 pb-0">
            {Object.entries(groupedInvoices).map(([message, invoiceGroup]: any, key) => {
              return (
                <div className="" key={key}>
                  {invoiceGroup.map((invoice: any, index: number) => {
                    return (
                      <div key={index} className={`request-withdraw-modal-list-body-item ${totalList.find(({ invoice_id }: any) => invoice.invoice_id === invoice_id) ? 'active' : ''}`} onClick={() => setTotalList(invoice)}>
                        <div className="text-xs flex-[4]" style={{ color: "var(--black5)" }}>
                          {invoice.invoice_id}
                        </div>

                        <div className="text-xs flex-[2]" style={{ color: "var(--black5)" }}>
                          {invoice.amount}
                          {/* @ts-ignore*/}
                          {CURRENCY_OPTIONS[invoice.currency].symbol}
                        </div>

                        <div className="flex-[2] justify-start flex">
                          {
                            invoice.status === "PENDING" ? <div className="badge badge-pending">{t("statuses.pending")}</div> :
                              invoice.status === "DECLINED" ? <div className="badge badge-cancelled">{t("statuses.declined")}</div> :
                                invoice.status === "PAID" ? <div className="badge badge-completed">{t("statuses.paid")}</div> :
                                  invoice.status === "TRANSFERRED" ? <div className="badge badge-trialing">{t("statuses.transferred")}</div> :
                                    <div className="badge badge-confirmation"> - ? - </div>
                          }
                        </div>
                      </div>
                    );
                  })}

                  {message ? <div className="request-withdraw-modal-list-body-item-message">
                    <div>{t("request.shopMessage")}</div>
                    <span>{message}</span>
                  </div> : null}
                </div>
              );
            })}
          </div>

        </div>

      </form>

    </Modal>

  </>
}

export default InvoicesModal;
