import customerApi from "../../../utils/customer-api";
import { InvoiceInfoWrapper, Response } from "../../../types/types";
import { formData } from "../../../utils/config";
// import { saveAs } from "file-saver";

export const getInvoiceByID = async(id?: string): Promise<Response<InvoiceInfoWrapper>> =>
  customerApi.get<never, Response<InvoiceInfoWrapper>>(`/invoices/unique/${id}`)
    .then((res) => {
      if(res.status === 200) {
        return res
      } else {
        throw res
      }
    })
    .catch(err => {
      console.error(err)
      throw err;
    });


export const getInvoiceInfo = async(id: string, secret: string): Promise<any> =>
  customerApi.get(`/invoices/info/${id}/${secret}`)
    .then((res: any) => {
      if(res && res.status === 200) {
        return res
      } else {
        throw res
      }
    }).catch(err => {
      throw err
    })


export const removeEVMApprove = async(invoice_id: string): Promise<any> =>
  customerApi.post(`/evm/approve/remove`, formData({ invoice_id }))
    .then((res: any) => {
      if(res && res.status === 200) {
        return res
      } else {
        throw res
      }
    }).catch(err => {
      throw err
    })

// export const saveFileAction = (url: string) => () => {
//     saveAs(url, "product.zip");
// }
