import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation }from "react-i18next";
import CodeAuthForm from "./components/form";
import useCustomerAuthCookies from "../../hooks/useCustomerAuthCookie";
import useCaptchaFallbackHook from "../../pages/profile/overview/useCaptchaFallbackHook";
import { Button, Spin } from "@sellix/shared/components";
import { toastAlert } from "@sellix/shared/components";
import { useNavigate }from "react-router-dom";
import {
  getCustomerEmailCode,
  getCustomerToken,
  CustomerEmailCodeProps,
  CustomerTokenProps
}from "./actions";
import { useAuthContext }from "../../provider/AuthContext";
import InitialScreen, { MainLoader } from "./initial-screen";
import { CustomerToken, CustomerEmailCode, Response }from "../../types/types";
import i18n from "../../utils/i18n";

import "./index.css";


const Login = (): ReactNode => {

  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'customer.auth' });

  const currentMode = localStorage.getItem("theme") || "light";
  const [touched, setTouched] = useState(false);
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [code, setCode] = useState('');
  const { setCookies } = useCustomerAuthCookies();
  const [skipFields, setSkipFields] = useState(true);
  const [sendingCode, setSendingCode] = useState(false);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const { setLoggedIn, shopId, customerMainPage } = useAuthContext();
  const refCaptcha = useRef(null);
  const { requestWithCaptchaV3, requestWithCaptchaV2 } = useCaptchaFallbackHook(refCaptcha);


  const handleSubmitCode = () => {

    const onSuccess = async(captcha?: any) => {
      setSendingCode(true);

      const values: CustomerEmailCodeProps = {
        email,
        captcha,
        shop_id: shopId
      };

      /**
       * @param data.fields_required Is extra user data required
       */
      const { data, status, error } = await getCustomerEmailCode(values) as Response<CustomerEmailCode | null>;

      if (status === 200) {
        if (data && data.fields_required) {
          setSkipFields(false);
        }
        setValidEmail(true);
      } else {
        toastAlert("error", error || 'Server Error!');
      }

      setSendingCode(false);
    }

    const onError = (error: any) => {
      if(error?.log?.recaptcha_error) {
        requestWithCaptchaV2(onSuccess, onError);
      } else {
        toastAlert('error', error ? (error.error || error.message) : "Server error!");
      }

      setSendingCode(false);
    }

    return requestWithCaptchaV3(onSuccess, onError);

  }

  const handleSubmitAuth = () => {

    const onSuccess = async(captcha?: string) => {
      setSendingCode(true);

      const values: CustomerTokenProps = {
        email,
        captcha: captcha || "",
        code,
        shop_id: shopId
      };

      if (!skipFields) {
        values.name = firstname
        values.surname = lastname
      }

      const { data, status, error } = await getCustomerToken(values) as Response<CustomerToken>;

      if (status === 200) {
        setCookies(data.token);

        if (data.token) {
          setLoggedIn(true);
          if(customerMainPage) {
            navigate(`/${customerMainPage}`, { replace: true });
          } else {
            navigate("/balances", { replace: true });
          }
        }
      } else {
        toastAlert("error", error || 'Server Error!');
      }

      setSendingCode(false);
    }

    const onError = (error: any) => {
      if(error?.log?.recaptcha_error) {
        requestWithCaptchaV2(onSuccess, onError);
      } else {
        toastAlert('error', error ? (error.error || error.message) : "Server error!");
      }

      setSendingCode(false);
    }

    return requestWithCaptchaV3(onSuccess, onError);
  }

  useEffect(() => {
    if(code.length === 8) {
      void handleSubmitAuth()?.catch((e) => console.log(e));
    }
  }, [code]);

  useEffect(() => {
    document.title = t('pageTitle');
  }, [t]);

  useEffect(() => {
    setValidEmail(false);
    setCode("");
    setSkipFields(true);
  }, [email]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !(sendingCode || !email)) {
      void handleSubmitCode();
    }
  };

  return <div className="auth-screen flex-col-reverse gap-8">

    <div ref={refCaptcha} className="auth-content-captcha"/>

    <InitialScreen/>

    <div className="auth">
      <div className="auth-block">

        <div className="auth-block-header">
          <div className="auth-block-header-title">
            {validEmail && email ? t('email.valid.title') : t('email.invalid.title')}
          </div>
          <div className="auth-block-header-subtitle">
            {validEmail && email ? t('email.valid.message') : t('email.invalid.message')}
          </div>
        </div>

        <div className="auth-block-form">

          <div className="flex flex-col w-full">

            <div className="auth-block-email flex-wrap">
              <div className="pb-3 w-full">
                <label htmlFor="email" className="sellix-label">
                  {t('email.title')}
                </label>
                <input
                  id="email"
                  type="text"
                  name="email"
                  onKeyDown={handleKeyDown}
                  onChange={(e): void => {
                    setEmail(e.target.value);
                  }}
                  onBlur={(): void => setTouched(true)}
                  value={email || ""}
                  placeholder={t('email.placeholder') || ""}
                  className={`w-full sellix-input ${!email && touched ? 'is-invalid' : ''}`}
                />
                {!email && touched && <div className="text-left invalid-feedback">
                  {t('email.isRequired')}
                </div>}
              </div>
              {!validEmail ?
                <Button variant="primary" size="large" className="w-full mt-2" disabled={sendingCode || !email}
                  onClick={handleSubmitCode}>
                  {sendingCode ? <Spin variant={currentMode === "dark" ? "black" : "white"}/> : t('continue')}
                </Button>
                : null}
            </div>

            {validEmail && email ? <CodeAuthForm
              setFirstName={setFirstName}
              setLastName={setLastName}
              setCode={setCode}
              firstname={firstname}
              lastname={lastname}
              code={code}
              skipFields={skipFields}
            /> : null}

            {validEmail && email ?
              <Button onClick={handleSubmitAuth}>
                {sendingCode ? <Spin variant={currentMode === "dark" ? "black" : "white"}/> : t("access")}
              </Button> :
              null
            }
          </div>
        </div>
      </div>
    </div>
  </div>

}


const LoaderContainer = () => {
  const { loading, isExpiredSubscription } = useAuthContext();

  if (isExpiredSubscription) {
    return <div>Seller subscription has expired.</div>
  }

  if(loading || !i18n.isInitialized) {
    return <div className="initial-screen">

      <MainLoader />

    </div>
  }

  return <Login />
}

export default LoaderContainer;
