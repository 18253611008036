import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ArrowLeftIcon } from "@sellix/shared/components";
import { SuccessCheckIcon } from "@sellix/shared/components";
import { PayPalIcon, StripeIcon } from "../icons.tsx";
import { useAuthContext } from "../../../../provider/AuthContext.ts";
import StripePaymentMethod from "./gateways/stripe.tsx";
import PayPalPaymentMethod from "./gateways/paypal.tsx";
import usePayPal from "../../../../hooks/usePayPal";
import config from "../../../../utils/config.tsx";

const PAYMENT_METHOD_STRIPE = "STRIPE";
const PAYMENT_METHOD_PAYPAL = "PAYPAL";

const CreatePaymentMethod = ({ onBack }: { onBack: () => void }) => {

  const { t } = useTranslation("translation", { keyPrefix: "customer.paymentMethods" });
  const { shopId, shopInfo, customerProfile } = useAuthContext();
  const [paymentMethod, setPaymentMethod] = useState<string>(PAYMENT_METHOD_STRIPE);
  const [status, setStatus] = useState<string>();
  usePayPal({ config, shopInfo });

  const handleGoBack = useCallback(() => {
    onBack();
  }, [setPaymentMethod]);

  const handleSelectPaymentMethod = useCallback((e: React.MouseEvent, paymentMethod: string) => {
    e.stopPropagation();
    setPaymentMethod(paymentMethod)
  }, [setPaymentMethod]);

  const handleStatusChange = useCallback((status: string, info?: string) => {
    setStatus(status);
  }, [setStatus]);

  return (
    <>
      <div className="payment-methods-block payment-methods-cards">
        <div className={`payment-methods-card ${paymentMethod === PAYMENT_METHOD_STRIPE ? "active" : ""}`} onClick={(e) => handleSelectPaymentMethod(e, PAYMENT_METHOD_STRIPE)}>
          <StripeIcon/>
          <span>Stripe</span>
        </div>

        <div className={`payment-methods-card ${paymentMethod === PAYMENT_METHOD_PAYPAL ? "active" : ""}`} onClick={(e) => handleSelectPaymentMethod(e, PAYMENT_METHOD_PAYPAL)}>
          <PayPalIcon/>
          <span>PayPal</span>
        </div>
      </div>


      {shopId && customerProfile?.email && !!paymentMethod && status !== "success" && (
        <div className="payment-methods-block">
          {paymentMethod === PAYMENT_METHOD_STRIPE && (
            <div className="payment-methods-stripe-card">
              <StripePaymentMethod onStatusChange={handleStatusChange} onGoBack={handleGoBack}/>
            </div>
          )}

          {paymentMethod === PAYMENT_METHOD_PAYPAL && (
            <div className="payment-methods-paypal-card">
              <PayPalPaymentMethod onGoBack={handleGoBack}/>
            </div>
          )}
        </div>
      )}

      {status === "success" && (
        <div className="payment-methods-block payment-methods-setup-message">
          <div className="payment-methods-setup-message-icon">
            <SuccessCheckIcon/>
          </div>
          <div className="payment-methods-setup-message-title">
            {t('phrases.addedSuccessfully')}
          </div>

          <Button
            className="mt-4"
            style={{ fontWeight: 600, padding: "0 .25rem", display: "flex", gap: "0.5rem" }}
            onClick={handleGoBack}
          >
            <ArrowLeftIcon/> {t('buttons.backToList')}
          </Button>
        </div>
      )}
    </>
  )
}

export default CreatePaymentMethod;
