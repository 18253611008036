import React, { ReactNode } from "react";
import { BalancesEmptyIcon, AffiliateEmptyIcon, InvoicesEmptyIcon } from "./icons";
import { useTranslation } from "react-i18next";

import "./index.css";

interface IEmpty {
    isBalances?: boolean
    isProducts?: boolean
    isInvoices?: boolean
    isSubscriptions?: boolean
    isLicenses?: boolean
    isRCON?: boolean
    isPaymentMethods?: boolean
    isGiftCards?: boolean
}

const Empty = ({ isBalances, isProducts, isInvoices, isSubscriptions, isLicenses, isRCON, isPaymentMethods, isGiftCards }: IEmpty): ReactNode => {

  const balancesTranslate = useTranslation('translation', { keyPrefix: 'customer.balances.empty' });
  const affiliateTranslate = useTranslation('translation', { keyPrefix: 'customer.affiliates.empty' });
  const invoicesTranslate = useTranslation('translation', { keyPrefix: 'customer.invoices.empty' });
  const licensesTranslate = useTranslation('translation', { keyPrefix: 'customer.licenses.empty' });
  const subscriptionsTranslate = useTranslation('translation', { keyPrefix: 'customer.subscriptions.empty' });
  const rconTranslate = useTranslation('translation', { keyPrefix: 'customer.rcon.empty' });
  const paymentMethodsTranslate = useTranslation('translation', { keyPrefix: 'customer.paymentMethods.empty' });
  const giftCardsTranslate = useTranslation('translation', { keyPrefix: 'customer.giftCards.empty' });

  return <div className="empty">

    <div className="empty-icon">
      {isBalances ? <BalancesEmptyIcon /> : null}
      {isProducts ? <AffiliateEmptyIcon /> : null}
      {isRCON || isInvoices || isSubscriptions || isLicenses ? <InvoicesEmptyIcon /> : null}
    </div>

    <div className="empty-title">
      {isBalances ? balancesTranslate.t('title') : null}
      {isProducts ? affiliateTranslate.t('title') : null}
      {isInvoices ? invoicesTranslate.t('title') : null}
      {isSubscriptions ? subscriptionsTranslate.t('title') : null}
      {isLicenses ? licensesTranslate.t('title') : null}
      {isRCON ? rconTranslate.t('title') : null}
      {isPaymentMethods ? paymentMethodsTranslate.t('title') : null}
      {isGiftCards ? giftCardsTranslate.t('title') : null}
    </div>

    <div className="empty-text">
      {isBalances ? balancesTranslate.t('message') : null}
      {isProducts ? affiliateTranslate.t('message') : null}
      {isInvoices ? invoicesTranslate.t('message') : null}
      {isSubscriptions ? subscriptionsTranslate.t('message') : null}
      {isLicenses ? licensesTranslate.t('message') : null}
      {isRCON ? rconTranslate.t('message') : null}
      {isPaymentMethods ? paymentMethodsTranslate.t('message') : null}
      {isGiftCards ? giftCardsTranslate.t('message') : null}
    </div>
  </div>
}

export default Empty;
