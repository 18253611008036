import React, { useState, useEffect, ReactNode } from "react";
import { getCustomerInfo as getCustomerInfoAction } from "../dashboard/actions";
import CustomerContext from './CustomerContext';
import { useAuthContext } from "./AuthContext";
import {
  BalanceInvoice,
  FundsRequest,
  CustomerInfo,
  Product,
  ProductNormalized,
  Response,
  RevenueInvoice
} from "../types/types";


const CustomerProvider = ({ children }: { children: ReactNode }) => {

  const { shopInfo, shopId, getShopInfo } = useAuthContext();

  const [mobileMenu, showMobileMenu] = useState(false);
  const [revenueInvoices, setRevenueInvoices] = useState<RevenueInvoice[]>([]);
  const [fundsRequests, setFundsRequests] = useState<FundsRequest[]>([]);
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>();
  const [affiliatedProducts, setAffiliatedProducts] = useState<Product[]>([]);

  const [customerBalanceHistory, setCustomerBalanceHistory] = useState<BalanceInvoice[]>([]);

  const getCustomerInfo = (shopId: number) => {
    getCustomerInfoAction(shopId)
      .then((res: Response<CustomerInfo>) => {
        if(res.status === 200) {

          setCustomerBalanceHistory((res.data.customer_balance_history || []).sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()));
          setRevenueInvoices(res.data.revenue_invoices?.map((invoice) => {
            if(!invoice.status) {
              invoice.status = "NO_ACTION";
            }
            return invoice;
          }) || []);

          setFundsRequests((res.data.funds_requests || []).sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()));

          setCustomerInfo({
            ...res.data.customer,
            ...res.data
          })
        }
      })
      .catch(() => {})
  }

  useEffect(() => {
    if(shopId) {
      getCustomerInfo(shopId);
    }
  }, [shopId]);

  const refetchCustomerInfo = () => shopId ? getCustomerInfo(shopId) : null;

  useEffect(() => {

    if(shopId && shopInfo && customerInfo) {

      const items = (shopInfo.items || []).map((item: Product) => {

        const minPriceItem = item.item_type === 'GROUP'
          ? (item.products_bound || []).reduce((res: any, obj: any) => {
            return !res || parseFloat(obj.price_display) < parseFloat(res.price_display) ? obj : res;
          }, null)
          : item;

        return {
          ...item,
          isInfo: item.item_type === 'PRODUCT' && item.type === 'INFO_CARD',
          isSubscription: item.item_type === 'PRODUCT' && item.type === 'SUBSCRIPTION',
          isProduct: item.item_type === 'PRODUCT' && !['INFO_CARD', 'SUBSCRIPTION'].includes(item.type),
          isGroup: item.item_type === 'GROUP',
          minPriceItem: minPriceItem && {
            price: minPriceItem.price,
            price_display: minPriceItem.price_display,
            currency: minPriceItem.currency,
          },
        };
      });

      let affiliatedProducts: ProductNormalized[] = [];

      items.forEach((product) => {
        if (product.isGroup) {
          (product.products_bound || []).forEach((p: Product) => {
            affiliatedProducts.push({
              ...p,
              isSubscription: product.type === 'SUBSCRIPTION',
              isProduct: product.type !== 'SUBSCRIPTION',
            });
          });
        } else if (product.isInfo) { /* empty */ } else {
          affiliatedProducts.push(product);
        }
      });

      affiliatedProducts = affiliatedProducts.filter((product: Product) => {

        const isFree = +product.price_display === 0 && !+product.pay_what_you_want;
        const isOnHold = product.on_hold;
        const isOut = !+product.stock;
        const isUnlisted = product.unlisted;
        const isShopAffiliateEnabled = customerInfo.shop.affiliate_revenue_percent;
        const productAffiliate = product.affiliate_revenue_percent || 0;
        const isCustomerAffiliateEnabled = customerInfo.customer.affiliate_revenue_percent || 0;

        let enableAffiliate: number | string | null = null;

        if (productAffiliate && +productAffiliate > 0) {
          enableAffiliate = productAffiliate;
        } else if (productAffiliate === 0 && isShopAffiliateEnabled) {
          enableAffiliate = isShopAffiliateEnabled;
        } else if (productAffiliate === -1) {
          enableAffiliate = null;
        }

        return !(isFree || isOnHold || isOut || isUnlisted || !enableAffiliate);
      });

      setAffiliatedProducts(affiliatedProducts);
    }

  }, [shopInfo, shopId, customerInfo?.customer.affiliate_revenue_percent, customerInfo?.shop.affiliate_revenue_percent, customerInfo]);


  const toggleMenu = () => {
    getShopInfo(true);
    // @ts-ignore
    getCustomerInfo(shopId);
    showMobileMenu(!mobileMenu);
  }

  const navigateDesktop = () => {
    getShopInfo(true);
    // @ts-ignore
    getCustomerInfo(shopId);
  }

  return (
    <CustomerContext.Provider
      value={{
        revenueInvoices,
        customerBalanceHistory,
        customerInfo,
        affiliatedProducts,
        fundsRequests,
        mobileMenu,
        toggleMenu,
        navigateDesktop,
        shopId,
        shopInfo,
        refetchCustomerInfo
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
}

export default CustomerProvider;
