import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@sellix/shared/components";
import {
  DashboardIcon,
  AffiliateIcon,
  SubscriptionIcon,
  InvoicesIcon,
  LicensesIcon,
  ProfileIcon,
  SignOutIcon,
  RewardsIcon, RconGamingIcon
} from "../assets/icons";
import { useAuthContext } from "../provider/AuthContext";
import { useCustomerContext } from "../provider/CustomerContext";
import Header from "./header";
import useMobile from "../hooks/useMobile";
import InitialScreen from "../pages/login/initial-screen";
import { useTranslation } from "react-i18next";

import "./index.css";


export const Layout = ({ children }: { children: ReactNode }) => {

  const { handleLogout,   hideCustomerAffiliate, hideCustomerRewards, hideCustomerBalances, hideCustomerSubscriptions, hideCustomerLicenses, hideCustomerGaming } = useAuthContext();
  const { toggleMenu, mobileMenu } = useCustomerContext();
  const isMobile = useMobile();
  const { t } = useTranslation("translation", { keyPrefix: "customer.menu" })

  return <div className="layout">

    <InitialScreen />
    <Header />

    <div className="page">
      {isMobile ? <div className={`navigation ${mobileMenu ? 'show' : 'hide'}`}>
        {hideCustomerBalances ? null : <NavLink onClick={toggleMenu} to="/balances" className={({ isActive }) => isActive ? "active" : ""}><DashboardIcon /> <span>{t('balances')}</span></NavLink>}
        {hideCustomerAffiliate ? null : <NavLink onClick={toggleMenu} to="/affiliate" className={({ isActive }) => isActive ? "active" : ""}><AffiliateIcon /> <span>{t('affiliates')}</span></NavLink>}
        {hideCustomerSubscriptions ? null : <NavLink onClick={toggleMenu} to="/subscriptions" className={({ isActive }) => isActive ? "active" : ""}><SubscriptionIcon /> <span>{t('subscriptions')}</span></NavLink>}
        <NavLink onClick={toggleMenu} to="/invoices" className={({ isActive }) => isActive ? "active" : ""}><InvoicesIcon /> <span>{t('invoices')}</span></NavLink>
        {hideCustomerLicenses ? null : <NavLink onClick={toggleMenu} to="/licenses" className={({ isActive }) => isActive ? "active" : ""}><LicensesIcon /> <span>{t('licenses')}</span></NavLink>}
        {hideCustomerGaming ? null : <NavLink onClick={toggleMenu} to="/rcon-gaming" className={({ isActive }) => isActive ? "active" : ""}><RconGamingIcon /> <span>{t('rconGaming')}</span></NavLink>}
        {hideCustomerRewards ? null : <NavLink onClick={toggleMenu} to="/rewards" className={({ isActive }) => isActive ? "active" : ""}><RewardsIcon /> <span>{t('rewards')}</span></NavLink>}
        <NavLink onClick={toggleMenu} to="/profile" className={({ isActive }) => isActive ? "active" : ""}><ProfileIcon /> <span>{t('profile')}</span></NavLink>
        <button onClick={handleLogout} className="cursor-pointer"><SignOutIcon /> <span>{t('signOut')}</span></button>
      </div> : null}

      <div className="content">
        {children}
      </div>
    </div>

    <Tooltip
      name="beta"
      tooltipText={"This feature is currently in BETA. You can use, but it might present bugs. Should you find any, please report them at roadmap.sellix.io/bugs"}
      isOutside
    />
  </div>
}

export default Layout;
