import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FIAT_SYMBOLS } from "@sellix/shared/configs";
import { Link } from "react-router-dom";
import Table from "../../../components/table";
import { useCustomerContext } from "../../../provider/CustomerContext";
import Empty from "../../../components/empty";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { BalanceInvoice } from "../../../types/types";
import { useAuthContext } from "../../../provider/AuthContext.ts";
import { search } from "../../../utils/config.tsx";
import Select from "../../../components/select";
import Search from "../../../components/search";
import { ChevronDownIcon, ChevronUpIcon } from "../../../assets/icons.tsx";


const FILTERS = [
  'invoice_id',
  'reward_id',
  'clause',
  'subscription_id',
  'gift_card_id'
];

const TYPE_OPTIONS = [
  { label: "All types", value: "all" },
  { label: "Top ups", value: "BALANCE_TOP_UP" },
  { label: "Gift cards", value: "GIFT_CARD" },
  { label: "Balance transfers", value: "BALANCE_TRANSFER" },
  { label: "Rewards", value: "REWARD" },
  { label: "Shop update", value: "SHOP_UPDATE" },
  { label: "Subscription notes", value: "SUBSCRIPTION_CREDIT_NOTE" },
  { label: "Balance used", value: "BALANCE_USED" }
]

const BalanceHistory = ({ setShowFull, showFull, activeRef }: { setShowFull: (v: boolean) => void, showFull: boolean, activeRef: any }) => {

  const { customerInfo, customerBalanceHistory } = useCustomerContext();
  const { shopInfo } = useAuthContext();
  const { t } = useTranslation('translation', { keyPrefix: "customer.profile.balanceHistory" });
  const [searchKey, setSearchKey] = useState('');
  const [searchType, setSearchType] = useState('all');


  const getType =  ({ row }: CellContext<BalanceInvoice, unknown>) => {

    switch (row.original.type) {
      case "BALANCE_TOP_UP":
        return (
          <div className="dashboard-info-product">
            <div>{t("table.row.topUp")}</div>
            <span>
              <Link to={`/invoices/${row.original.invoice_id}`}>{row.original.invoice_id}</Link>
            </span>
          </div>
        )

      case "GIFT_CARD":
        return (
          <div className="dashboard-info-product">
            <div>{t("table.row.giftCard")}</div>
            <span>
              {
                row.original.gift_card?.sender_email === customerInfo?.customer.email ?
                  <><Link to={`/invoices/${row.original.invoice_id}`}>{row.original.invoice_id}</Link></>
                  :
                  row.original.gift_card?.sender_email ?
                    <>{t("table.row.giftBy")}:&nbsp;<a href={`mailto:${row.original.gift_card.sender_email}`}>{row.original.gift_card.sender_email}</a></> :
                    <>{row.original.invoice_id}</>
              }
            </span>
          </div>
        )

      case "BALANCE_TRANSFER":
        return (
          <div className="dashboard-info-product">
            <div>{t("table.row.balanceTransferred")}</div>
            <span>
              {t("table.row.revenueRequest")}
              {/* ID:&nbsp;<span style={{ color: "var(--brand-color)" }}>{row.original.request_id}</span>*/}
            </span>
          </div>
        )

      case "REWARD":
        return (
          <div className="dashboard-info-product">
            <div>{t("table.row.reward")} {row.original.reward?.clause ? `(${t(`table.row.${row.original.reward.clause}`)})` : ''}</div>
            <span>
              <Link to={`/invoices/${row.original.invoice_id}`}>{row.original.invoice_id}</Link>
            </span>
          </div>
        )

      case "SHOP_UPDATE":
        return (
          <div className="dashboard-info-product">
            <div>{t("table.row.shopUpdate")}</div>
            <span>
              {t("table.row.hello", { name: shopInfo?.shop.name })}
            </span>
          </div>
        )

      case "SUBSCRIPTION_CREDIT_NOTE":
        return (
          <div className="dashboard-info-product">
            <div>{t("table.row.subscriptionCredit")}</div>
            <span>
              {t("table.row.subscription")}:&nbsp;<Link to={`/subscriptions/${row.original.subscription_id}`}>{row.original.subscription_id}</Link>
            </span>
          </div>
        )

      case "BALANCE_USED":
        return (
          <div className="dashboard-info-product">
            <div>{row.original.is_added ? t("table.row.balanceUsedCompleted") : t("table.row.balanceUsedBlocked")}</div>
            <span>
              {t("table.row.for")}&nbsp;<Link to={`/invoices/${row.original.invoice_id}`}>{row.original.invoice_id}</Link>
            </span>
          </div>
        )
    }
  }

  const columns2: ColumnDef<BalanceInvoice>[] = [{
    accessorKey: "type",
    header: t("table.columns.type") as string,
    cell: getType
  }, {
    accessorKey: "created_at",
    header: t("table.columns.created") as string,
    cell: ({ row }: CellContext<BalanceInvoice, unknown>) => moment(row.original.created_at * 1000).format("DD MMM YYYY (HH:mm)")
  }, {
    accessorKey: "amount",
    header: t("table.columns.amount") as string,
    cell: ({ row }: CellContext<BalanceInvoice, unknown>) => <div className="flex">{row.original.type === "BALANCE_USED" ? "-" : "+"}{FIAT_SYMBOLS[row.original.currency]}{row.original.amount}</div>
  }];


  const filterByStatus = (invoices: BalanceInvoice[]) => {
    if (searchType === "all") {
      return invoices;
    } else {
      return invoices.filter(({ type }) => type === searchType);
    }
  };


  let data = customerBalanceHistory;
  if (searchType) {
    data = filterByStatus(customerBalanceHistory);
  }
  if (searchKey) {
    data = search(data, FILTERS, searchKey);
  }


  // @ts-ignore
  return customerBalanceHistory.length ?
    <div ref={activeRef}>

      <div className="gift-card-title flex gap-4 items-center mb-0 cursor-pointer" onClick={() => setShowFull(!showFull)}>
        <div className="flex gap-4 items-center">
          {t("title")}<span className="badge badge-sellix" data-tooltip-id="beta">Beta</span>
        </div>
        <div>
          {showFull ? <ChevronUpIcon big /> : <ChevronDownIcon big />}
        </div>
      </div>

      {showFull ?
        <div>
          <div className="gap-2 justify-end w-100 flex">
            <div className="flex flex-wrap gap-3">
              <Select
                size="large"
                value={searchType}
                onChange={e => setSearchType(e.target.value)}
                options={TYPE_OPTIONS.map(({ label, value }) => ({ label, value }))}
              />
              <Search onChange={setSearchKey} search={searchKey} className="mb-0"/>
            </div>
          </div>
          <div className="dashboard-info-table mt-4">
            {/* <span>Balance History</span>*/}
            <Table pageSize={10} data={data} columns={columns2} className="last-right"/>
          </div>
        </div>
        : null}
    </div>
    :
    <div>
      <Empty isBalances />
    </div>;
}

export default BalanceHistory;
