import customerApi from "../../utils/customer-api";
import { formData } from "../../utils/config"
import { CancelBill, CancelReason, Response, SubscriptionWrapper } from "../../types/types";
import api from "../../utils/api.tsx";

export const getRecurring = async(): Promise<Response<SubscriptionWrapper>> => {
  const res = await customerApi.get<never, Response<SubscriptionWrapper>>(`self_customer/invoices/recurring_bills`);

  if (res.status === 200) {
    return res
  } else {
    console.error(res);
    throw res;
  }
}

export const getRecurringById = async(id?: string): Promise<Response<SubscriptionWrapper>> => {
  const res = await customerApi.get<never, Response<SubscriptionWrapper>>(`invoices_customer/recurring_bill/${id}`)

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}

export const getCancelReasons = async(): Promise<Response<CancelReason[]>> => {
  const res = await customerApi.get<never, Response<CancelReason[]>>(`/dicts/subscription_cancel_reasons`)
  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}

interface CancelBillRequestPayload {
  id: string
  cancel_reason_id?: number
  cancel_reason_comment?: string | null
}

export const cancelBill = async(id: string, reason?: CancelReason, reasonComment?: string): Promise<Response<CancelBill>> => {
  const data: CancelBillRequestPayload = { id };
  if (reason) {
    data.cancel_reason_id = reason.id;
    if (reasonComment) {
      data.cancel_reason_comment = reasonComment;
    }
  }
  const res = await customerApi.post<never, Response<CancelBill>>(`invoices_customer/recurring_bill/cancel`, formData(data));

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}


export const evmSpenders = async(): Promise<Response<any>> => {
  const res = await api.get<never, Response<CancelBill>>(`evm/approved_spenders`);

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}
