import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/modal";
import { RevenueInvoice } from "../../../types/types.ts";

import "./index.css";


const CommentModal = ({ isOpen, close, invoice }: { isOpen: string | boolean, close: () => void, invoice: RevenueInvoice | null }) => {

  const { t } = useTranslation("translation", { keyPrefix: "customer.balances.modals" });

  if(!isOpen || !invoice) {
    return null;
  }

  return <>

    <Modal open={Boolean(isOpen)} className="sellix-top-up-modal" closeModal={close}>
      <div className="sm:p-8 sm:pb-6 p-4 pb-3 border-b-0">
        <div className="new-modal-title mb-0">
          Revenue Invoice
        </div>
      </div>
      <div className="p-4 pt-0 pb-0 sm:pt-0 sm:pb-0 sm:p-8">

        <div className="request-withdraw-modal-list pt-0 mt-0 pb-2">

          <div style={{
            display: "flex",
            alignItems: "center",
            padding: "1rem 1.5rem 0rem 1.5rem",
            gap: ".25rem",
            color: "var(--black9)",
            fontSize: 13
          }}>
            <div>{t("status")}:</div>
            {invoice.status === "DECLINED" ? <div className="badge badge-cancelled">{t("statuses.declined")}</div> : null}
            {invoice.status === "PAID" ? <div className="badge badge-completed">{t("statuses.paid")}</div> : null}
            {invoice.status === "TRANSFERRED" ? <div className="badge badge-trialing">{t("statuses.transferred")}</div> : null}
          </div>

          <div className="request-withdraw-modal-list-body mb-0 pb-0">
            <div className="request-withdraw-modal-list-body-item-message">
              <div>{t("request.shopMessage")}</div>
              <span>{invoice.message}</span>
            </div>
          </div>

        </div>

      </div>

    </Modal>

  </>
}

export default CommentModal;
