import { PaymentMethodsWrapper, Response } from '../../../types/types.ts';
import customerApi from '../../../utils/customer-api.ts';
import { formData } from "../../../utils/config.tsx";

export const getPaymentMethods = async(): Promise<Response<PaymentMethodsWrapper>> => {
  const res = await customerApi.get<never, Response<PaymentMethodsWrapper>>(
    `customer/payment_methods`,
  );

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}

export const deletePaymentMethod = async(id: number): Promise<Response<undefined>> => {
  const res = await customerApi.post<never, Response<undefined>>(
    `customer/payment_methods/delete`,
    formData({ id }),
  );

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}
