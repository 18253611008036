import React, { useCallback, useEffect } from "react";
import { useAuthContext } from "../../provider/AuthContext";
import useMobile from "../../hooks/useMobile";
import {
  AffiliateIcon,
  DashboardIcon,
  InvoicesIcon, LicensesIcon,
  MenuIcon,
  RconGamingIcon, RewardsIcon, SignOutIcon,
  SubscriptionIcon,
  EmailIcon, PhoneIcon, LocationIcon, LangIcon, ThemeIcon, PaymentMethodsIcon
} from "../../assets/icons";

import { useCustomerContext } from "../../provider/CustomerContext";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownItem } from "@sellix/shared/components";
import config, { getCDNLink } from "../../utils/config";
import i18n from "../../utils/i18n.tsx";

import "./index.css";


export const Theme = () => {

  const { customTheme, isCustom } = useAuthContext();
  const { t } = useTranslation("translation", { keyPrefix: "customer.menu" })
  const [isDark, setIsDark] = React.useState<boolean>(false);

  const changeTheme = () => {
    const currentMode = localStorage.getItem("theme") || "light";
    const newMode = currentMode === "dark" ? "light" : "dark";
    localStorage.setItem("theme", newMode);

    setIsDark((newMode) === "dark");
    applyTheme(newMode);
  };

  const applyTheme = (theme: string) => {
    if (!isCustom) {
      document.documentElement.classList.remove("dark", "light");
      document.documentElement.classList.add(theme);
    }
  };

  useEffect(() => {
    if (!customTheme) {
      const currentMode = localStorage.getItem("theme") || "light";
      setIsDark(currentMode === "dark");
      applyTheme(currentMode || "light");
    }
  }, [customTheme]);

  if (customTheme) {
    return null;
  }

  return <label htmlFor="lang" className="header-menu-item color-switch mx-0" style={{ width: "unset" }}>
    <div className="flex items-center gap-2"><ThemeIcon/> <span>{t("switch")}</span></div>
    <label className="color-switch-container">
      <input checked={isDark} id="lang" name="lang" type="checkbox" onChange={changeTheme}
        className="color-switch-checkbox"/>
      <div className="color-switch-slider"/>
    </label>
  </label>
}

const UserIcon = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path
    d="M10 10.8333C11.3807 10.8333 12.5 9.71404 12.5 8.33333C12.5 6.95262 11.3807 5.83333 10 5.83333C8.61929 5.83333 7.5 6.95262 7.5 8.33333C7.5 9.71404 8.61929 10.8333 10 10.8333Z"
    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path
    d="M5.13989 15.7075C5.34615 15.021 5.7682 14.4193 6.34343 13.9916C6.91866 13.564 7.61643 13.3331 8.33323 13.3333H11.6666C12.3843 13.3331 13.0829 13.5645 13.6585 13.9932C14.2342 14.4218 14.6561 15.0248 14.8616 15.7125"
    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


export const Lang = () => {

  const { t } = useTranslation("translation", { keyPrefix: "customer.menu" })

  const LangTrigger = () => {
    return <div className="header-menu-item">
      <div className="flex items-center gap-2 justify-between">
        <LangIcon/>
        {/* @ts-ignore*/}
        <span>{t("language")}: {config.LANGUAGES[i18n.language].title}</span>
      </div>
      {/* @ts-ignore*/}
      <img src={config.LANGUAGES[i18n.language].imgUrl} alt="" style={{ width: "1.25rem" }}/>
    </div>
  }

  const onUpdateLanguage = (lang: string) => {
    try {
      void i18n.changeLanguage(lang);
      window.localStorage.setItem("user-language", lang);
    } catch (e) {

    }
  }

  return <span className="navigation m-0 p-0" style={{ width: "unset" }} onClick={(e) => {
    e.preventDefault();
    e.stopPropagation();
  }}>
    <Dropdown className="navigation-dropdown lang-dropdown" position="bottom" dropdownTrigger={<LangTrigger/>}>
      {Object.keys(config.LANGUAGES).map((l, key) => (
        <DropdownItem key={key} onClick={() => onUpdateLanguage(l)} className={`navigation-item ${i18n.language === l ? 'active' : ''}`}>
          <>
            {/* @ts-ignore*/}
            <img src={config.LANGUAGES[l].imgUrl} alt="" style={{ width: "1.25rem" }}/>
            {/* @ts-ignore*/}
            <span>{config.LANGUAGES[l].title}</span>
          </>
        </DropdownItem>
      ))}
    </Dropdown>
  </span>
}


export const Layout = () => {

  const {
    customerProfile,
    handleLogout,
    businessName,
    hideCustomerAffiliate,
    customerMainPage,
    hideCustomerRewards,
    hideCustomerSubscriptions,
    hideCustomerLicenses,
    hideCustomerGaming,
    hideCustomerBalances
  } = useAuthContext();
  const { shopInfo, toggleMenu, navigateDesktop } = useCustomerContext();
  const isMobile = useMobile();
  const { t } = useTranslation("translation", { keyPrefix: "customer.menu" })
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const setActive = (name: string) => pathname.includes(name) ? "active" : "";
  const productImage = shopInfo?.shop.cloudflare_image_id ? getCDNLink({
    id: shopInfo.shop.cloudflare_image_id,
    type: 'shopItem'
  }) : null;


  if (!customerProfile) {
    return null
  }

  const ProfileTrigger = () => <div className="header-info unselectable">
    <div>
      {(customerProfile.email || "").charAt(0)}
    </div>
    <div>
      <div>
        {customerProfile.name} {customerProfile.surname}
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 6.5L8 10.5L12 6.5" stroke="#A2AAB4" strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round"/>
        </svg>
      </div>
      <span>{(customerProfile.email || "").substring(0, (customerProfile.email || "").indexOf('@'))}</span>
    </div>
  </div>;

  const InvoiceTrigger = () => <div
    className={`navigation-item ${setActive('subscriptions')} ${setActive('invoices')} ${setActive('licenses')}`}>
    {
      pathname.includes('/subscriptions') ? <><SubscriptionIcon/> {t('subscriptions')}</> :
        pathname.includes('/licenses') ? <><LicensesIcon/> {t('licenses')}</> :
          <><InvoicesIcon/> {t('invoices')}</>
    }
  </div>

  const hidedMenusCount = [hideCustomerAffiliate, hideCustomerRewards, hideCustomerGaming, hideCustomerBalances].filter((a) => a).length;

  return <div className="header">
    <div className="header-container flex-wrap">

      <div className="flex gap-6">
        <div className="flex items-center">
          {isMobile ? <div className="position-relative cursor-pointer mr-3" onClick={toggleMenu}>
            <MenuIcon/>
          </div> : null}

          <a href="/" className="header-logo">
            {productImage ? <img src={productImage} alt=""/> : businessName || shopInfo?.shop.name}
          </a>
        </div>

        {isMobile ? null : <div className="navigation navigation-header">
          {hideCustomerBalances ? null : <NavLink onClick={navigateDesktop} to="/balances"
            className={({ isActive }) => isActive ? "active navigation-item" : "navigation-item"}><DashboardIcon/><span>{t('balances')}</span></NavLink>}
          {hideCustomerAffiliate ? null : <NavLink onClick={navigateDesktop} to="/affiliate"
            className={({ isActive }) => isActive ? "active navigation-item" : "navigation-item"}><AffiliateIcon/><span>{t('affiliates')}</span></NavLink>}

          {hideCustomerSubscriptions && hideCustomerLicenses ?
            <NavLink onClick={navigateDesktop} to="/invoices"
              className={({ isActive }) => isActive ? "active navigation-item" : "navigation-item"}><InvoicesIcon/>
              <span>{t('invoices')}</span></NavLink>
            :
            hidedMenusCount >= 2 ?
              <>
                <NavLink onClick={navigateDesktop} to="/invoices"
                  className={({ isActive }) => isActive ? "active navigation-item" : "navigation-item"}><InvoicesIcon/>
                  <span>{t('invoices')}</span></NavLink>
                {hideCustomerSubscriptions ? null : <NavLink onClick={navigateDesktop} to="/subscriptions"
                  className={({ isActive }) => isActive ? "active navigation-item" : "navigation-item"}><SubscriptionIcon/>
                  <span>{t('subscriptions')}</span></NavLink>}
                {hideCustomerLicenses ? null : <NavLink onClick={navigateDesktop} to="/licenses"
                  className={({ isActive }) => isActive ? "active navigation-item" : "navigation-item"}><LicensesIcon/>
                  <span>{t('licenses')}</span></NavLink>}
              </>
              :
              <Dropdown className="navigation-dropdown" position="bottom" dropdownTrigger={<InvoiceTrigger/>}>
                <DropdownItem onClick={() => navigate("/invoices")}
                  className={`navigation-item ${setActive('invoices')}`}>
                  <InvoicesIcon/> {t('invoices')}
                </DropdownItem>
                {hideCustomerSubscriptions ? null : <DropdownItem onClick={() => navigate("/subscriptions")}
                  className={`navigation-item ${setActive('subscriptions')}`}>
                  <SubscriptionIcon/> {t('subscriptions')}
                </DropdownItem>}
                {hideCustomerLicenses ? null : <DropdownItem onClick={() => navigate("/licenses")}
                  className={`navigation-item ${setActive('licenses')}`}>
                  <LicensesIcon/> {t('licenses')}
                </DropdownItem>}
              </Dropdown>
          }

          {hideCustomerRewards ? null : <NavLink onClick={navigateDesktop} to="/rewards"
            className={({ isActive }) => isActive ? "active navigation-item" : "navigation-item"}>
            <RewardsIcon/>
            <span>{t('rewards')}</span>
          </NavLink>}

          {hideCustomerGaming ? null : <NavLink onClick={navigateDesktop} to="/rcon-gaming"
            className={({ isActive }) => isActive ? "active navigation-item" : "navigation-item"}>
            <RconGamingIcon/>
            <span>{t('rconGaming')}</span>
          </NavLink>}
        </div>}
      </div>

      <div className="flex items-center gap-2">
        <Dropdown position="bottom" className="header-profile-dropdown" dropdownTrigger={<ProfileTrigger/>}>

          <div className="header-menu pt-2">

            <DropdownItem onClick={() => navigate("/profile")} className={`navigation-item mx-2 ${setActive('profile')}`}>
              <UserIcon/> {t('profile')}
            </DropdownItem>

            <Lang />

            <Theme />

          </div>

          <DropdownItem onClick={() => handleLogout()} className={`navigation-item cursor-pointer mx-2 mb-2`}>
            <SignOutIcon/> {t("logout")}
          </DropdownItem>

        </Dropdown>
      </div>
    </div>
  </div>
}

export default Layout;