import React from "react";
import moment from "moment";
import RewardValue from "./reward-value";
import config from "../../../../../utils/config";
import { InvoiceInfo } from "../../../../../types/types.ts";

import "./index.css";


const Rewards = ({ invoice }: { invoice: InvoiceInfo }) => {

  const { rewards_data = [] } = invoice;

  if (!rewards_data.length) {
    return null;
  }

  return <div className="info-table with-shadow invoice-details-rewards">
    <div className="info-table-header">
      Issued Rewards
    </div>

    <div className="invoice-details-rewards-header">
      <div>Reward</div>
      <div>Reward Type</div>
      <div>Date</div>
    </div>

    <div className="invoice-details-rewards-items">
      {rewards_data.map((reward, idx) => (
        <div className="invoice-details-rewards-item" key={idx}>
          <div>
            {/* @ts-ignore*/}
            {config.REWARDS.CLAUSES[reward.clause]?.title}
          </div>
          <div>
            <RewardValue reward={reward} />
          </div>
          <div>
            {moment(reward.created_at * 1000).utc().format('DD MMM, YYYY')}
          </div>
        </div>
      ))}
    </div>
  </div>
}

export default Rewards;