import React, { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../../provider/AuthContext";
import Overview from "./overview";
import GiftCards from "./gift-cards";
import BalanceHistory from "./history";
import { useCustomerContext } from "../../provider/CustomerContext.tsx";
import PaymentMethods from "./payment-methods";

import "./index.css";


const Profile = () => {

  const { customerProfile, shopInfo } = useAuthContext();
  const { customerBalanceHistory } = useCustomerContext();

  const [showFullHistory, setShowFullHistory] = useState(false);
  const [showFullGiftCards, setShowFullGiftCards] = useState(false);
  const [showFullPaymentMethods, setShowFullPaymentMethods] = useState(false);

  const historyRef = useRef(null);
  const giftCardsRef = useRef(null);
  const paymentMethodsRef = useRef(null);

  const historyScroll = () => {
    setTimeout(() => {
      // @ts-ignore
      historyRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 500)
  };

  const giftCardScroll = () => {
    setTimeout(() => {
      // @ts-ignore
      giftCardsRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 500)
  };

  const paymentMethodsScroll = () => {
    setTimeout(() => {
      // @ts-ignore
      paymentMethodsRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 500)
  };

  useEffect(() => {
    historyScroll()
  }, [showFullHistory])

  useEffect(() => {
    giftCardScroll()
  }, [showFullGiftCards])

  useEffect(() => {
    paymentMethodsScroll()
  }, [showFullPaymentMethods])


  if(!customerProfile) {
    return null;
  }

  return <div className="profile-screen">

    <div className="w-full">

      <Overview setShowHistory={() => setShowFullHistory(true)} />
      <PaymentMethods setShowFull={setShowFullPaymentMethods} showFull={showFullPaymentMethods} activeRef={paymentMethodsRef} />
      {shopInfo?.shop.enable_gift_cards ? <GiftCards setShowFull={setShowFullGiftCards} showFull={showFullGiftCards} activeRef={giftCardsRef} /> : null}
      {customerBalanceHistory.length ? <BalanceHistory setShowFull={setShowFullHistory} showFull={showFullHistory} activeRef={historyRef} /> : null}

    </div>
  </div>
}

export default Profile;
