import React from "react";
import { CURRENCY_OPTIONS } from "@sellix/shared/configs/gateways";
import { Price } from "@sellix/shared/components/price";
import { Link } from "react-router-dom";


// @ts-ignore
const RewardValue = ({ reward }: { reward: any }) => {

  let value, title;
  const rewardInfo = reward.reward_info;

  if (!rewardInfo) {
    return null;
  }

  switch (reward.action) {
    case 'SEND_COUPON_CODE':
      title = 'Coupon';
      value = <Link to={`/coupons/edit/${rewardInfo.coupon.uniqid}`} target="_blank" style={{ textAlign: "left" }}>
        {rewardInfo.coupon.code}
      </Link>;
      break;
    case 'SEND_PRODUCT':
      title = rewardInfo.product.type === 'SUBSCRIPTION'
        ? 'Subscription'
        : (rewardInfo.product.licensing_enabled ? 'License' : 'Product');
      value = <Link to={`/products/edit/${rewardInfo.product.uniqid}`} target="_blank" style={{ textAlign: "left" }}>
        {rewardInfo.product.title}
      </Link>;
      break;
    case 'TOP_UP_BALANCE_AMOUNT':
      // @ts-ignore eslint-disable-next-line no-case-declarations
      title = 'Balance';
      // @ts-ignore
      value = <Price currency={CURRENCY_OPTIONS[rewardInfo.currency]} price={rewardInfo.total} />
      break;
    case 'TOP_UP_BALANCE_PERCENT':
      title = 'Balance'
      value = `${reward.action_value}%`;
      break;
  }

  return <>
    <span className="pr-1">
      {title}:
    </span>
    {value}
  </>;
};

export default RewardValue;