import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin, Button } from "@sellix/shared/components";
import { CURRENCY_OPTIONS, Currency } from "@sellix/shared/configs";
import { requestWithdrawFunds } from "./actions.tsx";
import { useCustomerContext } from "../../../provider/CustomerContext.tsx";
import { toastAlert } from "@sellix/shared/components";
import Modal from "../../../components/modal";
import { RevenueInvoice } from "../../../types/types.ts";

import "./index.css";


type RequestWithdrawModalProps = {
  isOpen: boolean
  close: () => void
  revenueInvoices: RevenueInvoice[]
  isWithdrawal: boolean
  setWithdrawRequest: (t: "WITHDRAWAL" | "TRANSFER") => void
  activeRevenueInvoice: RevenueInvoice | null
}


const RequestWithdrawModal = ({ isOpen, close, revenueInvoices, setWithdrawRequest, isWithdrawal, activeRevenueInvoice }: RequestWithdrawModalProps) => {

  const { customerInfo, shopInfo, refetchCustomerInfo } = useCustomerContext();
  const { t } = useTranslation("translation", { keyPrefix: "customer.balances.modals" });

  const [totalList, setTotal] = useState<RevenueInvoice[]>([]);
  const [loading, setLoading] = useState(false);
  const [customerNote, setCustomerNote] = useState<any>("");

  const currentMode = localStorage.getItem("theme") || "light";

  const setTotalInvoices = (invoice: any) => {
    if(totalList.find(({ invoice_id }: any) => invoice_id === invoice.invoice_id)) {
      setTotal(totalList.filter(({ invoice_id }: any) => invoice_id !== invoice.invoice_id));
    } else {
      setTotal([...totalList, invoice]);
    }
  }

  useEffect(() => {
    if(isOpen && activeRevenueInvoice) {
      setTotal([activeRevenueInvoice]);
    }
  }, [isOpen, activeRevenueInvoice]);

  useEffect(() => {
    try {
      const note = localStorage.getItem(`withdrawal-note-${shopInfo?.shop.id}`);
      setCustomerNote(note ? note : "");
    } catch (error) {
      console.error(error);
    }
  }, []);

  const submit = useCallback(async() => {

    try {
      setLoading(true);

      const res = await requestWithdrawFunds({
        invoice_ids: totalList.map(({ invoice_id }) => invoice_id),
        type: isWithdrawal ? "WITHDRAWAL" : "TRANSFER",
        customer_note: isWithdrawal ? customerNote : "",
        amount: totalList.reduce((acc: number, invoice: any): number => acc + +(invoice.amount), 0), // @ts-ignore
        customer_id: customerInfo.id, // @ts-ignore
        shop_id: shopInfo?.shop.id
      });

      if(res.status === 200) {
        try {
          localStorage.setItem(`withdrawal-note-${shopInfo?.shop.id}`, customerNote);
        } catch (error) {
          console.error(error);
        }
        setTotal([]);
        setCustomerNote("");
        refetchCustomerInfo();
        close();
        toastAlert("success", res.message || "");
      } else {
        throw res;
      }

    } catch (error: any) {
      toastAlert("error", (error ? (error.error || error.message) : "") || "Server Error!");
    } finally {
      setLoading(false);
    }
  }, [totalList, isWithdrawal, customerInfo, shopInfo, customerNote]);

  const switchType = (type: "TRANSFER" | "WITHDRAWAL") => {
    setWithdrawRequest(type);
  }

  if(!isOpen) {
    return null;
  }

  return <>

    <Modal open={isOpen} className="sellix-top-up-modal" closeModal={() => {
      close();
    }}>
      <div className="sm:p-8 sm:pb-6 p-4 pb-3 border-b-0">
        <div className="new-modal-title">
          {isWithdrawal ? t("withdrawal.title") : t("transfer.title")}
        </div>
        <div className="new-modal-description">
          {isWithdrawal ? t("withdrawal.subtitle") : t("transfer.subtitle")}
        </div>
      </div>
      <form className="p-4 pt-0 pb-0 sm:pt-0 sm:pb-0 sm:p-8" onSubmit={(e) => e.preventDefault()}>

        <div className="request-withdraw-modal-type">
          <label htmlFor="" className="sellix-label">{t("type")}</label>
          <div className="flex gap-2">
            <Button className="w-full" size="small" onClick={switchType.bind(this, "TRANSFER")} disabled={!isWithdrawal}>
              {t("transferType")}
            </Button>
            <Button className="w-full" size="small" onClick={switchType.bind(this, "WITHDRAWAL")} disabled={isWithdrawal}>
              {t("withdrawalType")}
            </Button>
          </div>
        </div>

        <div className="request-withdraw-modal-list">
          <div className="flex request-withdraw-modal-list-header text-sm">
            <div className="flex-[4]">{t("invoice")}</div>
            <div className="flex-[2]">{t("amount")}</div>
            <div className="flex-[1] justify-end flex">
              <span
                className="request-withdraw-modal-list-header-select"
                onClick={() => {
                  if(totalList.length === revenueInvoices.length) {
                    setTotal([]);
                  } else {
                    setTotal(revenueInvoices);
                  }
                }}
              >
                {totalList.length === revenueInvoices.length ? t("unselect") : t("select")}
              </span>
            </div>
          </div>

          <div className="request-withdraw-modal-list-body">
            {revenueInvoices.filter(({ status }) => status === "NO_ACTION").map((invoice, index) => (
              <div key={index} className={`request-withdraw-modal-list-body-item ${totalList.find(({ invoice_id }: any) => invoice.invoice_id === invoice_id) ? 'active' : ''}`} onClick={() => setTotalInvoices(invoice)}>
                <div className="text-xs flex-[4]" style={{ color: "var(--black5)" }}>
                  {invoice.invoice_id}
                </div>

                <div className="text-xs flex-[2]" style={{ color: "var(--black5)" }}>
                  {invoice.amount}
                  {CURRENCY_OPTIONS[invoice.currency as unknown as Currency].symbol}
                </div>

                <div className="flex-[1] justify-end flex">
                  <div className="request-withdraw-modal-list-body-item-checkbox" />
                </div>
              </div>
            ))}
          </div>

          <div className="request-withdraw-modal-list-divider" />

          <div className="request-withdraw-modal-list-footer">
            <div className="flex-[4]">{t("total")}</div>
            <div className="flex-[2]"/>
            <div className="flex-[1] justify-end flex">
              ${totalList.reduce((acc: number, invoice: any): number => acc + +(invoice.amount), 0).toFixed(2)}
            </div>
          </div>
        </div>


        {
          isWithdrawal &&
            <div className="request-withdraw-modal-note">
              <label htmlFor="" className="sellix-label">{t("withdrawal.messageLabel")}</label>
              <textarea autoFocus
                placeholder="Bitcoin: 1333P1eP5QGefi2D333fTL333mv7Di333a"
                className="sellix-input textarea !rounded-r-[0px]" value={customerNote}
                onChange={(e) => setCustomerNote(e.target.value)}
              />
            </div>
        }

      </form>


      <div className="justify-between border-t-0 p-4 pt-3 sm:p-8 sm:pt-6">
        <Button className="w-full" size="extra-large" disabled={loading || (isWithdrawal && !customerNote) || !totalList.map(({ invoice_id }: any) => invoice_id).length} onClick={submit}>
          {loading ? <Spin variant={currentMode === "dark" ? "black" : "white"}/> : isWithdrawal ? t("withdrawal.submit") : t("transfer.submit")}
        </Button>
      </div>
    </Modal>

  </>
}

export default RequestWithdrawModal;
