import React from "react";
import { orderStatus } from "../../../../../utils/config";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@sellix/shared/components";
import { InvoiceInfo } from "../../../../../types/types";
import { FIAT_SYMBOLS } from "@sellix/shared/configs";


const Badges = ({ invoice }: { invoice: InvoiceInfo }) => {

  const { void_details, approved_address, status_details = '', status = '', currency, crypto_received, exchange_rate, crypto_exchange_rate, cashapp_partial_amount_received, gateway, paypal_fee, total_display } = invoice;
  const { t } = useTranslation('translation', { keyPrefix: "customer.invoices.details.header.badges" });
  const invoiceTranslate = useTranslation("translation", { keyPrefix: 'customer.invoices.filters.statuses' });

  const total = +total_display;

  let totalReceived;

  if(gateway === "CASH_APP") {
    totalReceived = cashapp_partial_amount_received;
  } else {
    totalReceived = crypto_received * crypto_exchange_rate
    if (currency !== "USD") {
      totalReceived = totalReceived * exchange_rate
    }
    if (totalReceived >= total) {
      totalReceived = total - 0.01
    }
  }

  if (totalReceived) {
    totalReceived = (+totalReceived).toFixed(2)
  }

  return <div className="flex">

    {/* {type === 'SHOPPING_CART' &&*/}
    {/*	<span className="badge badge-developer ml-2">*/}
    {/*        Shopping Cart*/}
    {/*    </span>*/}
    {/* }*/}

    <span className={`badge badge-${(status).toLowerCase()} ml-2`}>
      {orderStatus(invoiceTranslate.t)[status]}
    </span>

    {status === 'PARTIAL' &&
      <span className={`badge badge-${(status).toLowerCase()} ml-2`}>
        {FIAT_SYMBOLS[currency]}{totalReceived} {t('of')} {FIAT_SYMBOLS[currency]}{total}
      </span>
    }

    {gateway === "PAYPAL" && +paypal_fee > 0 &&
      <span className="badge badge-pending ml-2">
        {FIAT_SYMBOLS[currency]}{(+total_display - +paypal_fee).toFixed(2)} of {FIAT_SYMBOLS[currency]}{(+total_display).toFixed(2)} due to PayPal fees
      </span>
    }

    {status === "VOIDED" && void_details === "PRODUCT_SOLD_OUT" &&
      <span className="badge badge-cancelled ml-2">
        {t('soldOut')}
      </span>
    }

    {status === "VOIDED" && void_details === "CART_PRODUCTS_SOLD_OUT" &&
      <span className={`badge badge-cancelled ml-2`}>
        {t('cartSoldOut')}
      </span>
    }

    {status === "COMPLETED" && status_details === "CART_PARTIAL_OUT_OF_STOCK" &&
      <span className={`badge badge-cancelled ml-2`}>
        {t('partialOut')}
      </span>
    }

    {approved_address ?
      <Tooltip name="approved" place="left" tooltipText={`You have allowed us to automatically use your ${gateway} funds to pay for invoices on recurring bills.`}>
        <div className="badge badge-confirmation">
            Allowance
        </div>
      </Tooltip>
      : null}


  </div>
}

export default Badges;
