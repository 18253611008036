import React, { useMemo, useState } from "react";
import { CartIcon, SubsIcon, ProductIcon, ArrowRightIcon } from "../../assets/icons";
import { orderStatus } from '../../utils/config';
import { GATEWAYS_OPTIONS, FIAT_SYMBOLS } from "@sellix/shared/configs";
import moment from "moment";
import Pagination from "../../components/pagination";
import ClearFilters from "../../components/table/clear-filters";
import { useTranslation } from "react-i18next";
import { InvoiceInfo } from "../../types/types";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@sellix/shared/components";


const MobileProducts = ({ data, skipGateway, clearFilters }: { data: InvoiceInfo[], skipGateway?: boolean, clearFilters?: () => void }) => {

  const { t } = useTranslation('translation', { keyPrefix: "customer.invoices" });
  const invoiceTranslate = useTranslation("translation", { keyPrefix: 'customer.invoices.filters.statuses' });
  const navigate = useNavigate();

  const SIZE = 3;

  const [currentPage, setCurrentPage] = useState<number | "...">(1);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (+currentPage - 1) * SIZE;
    const lastPageIndex = firstPageIndex + SIZE;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, SIZE]);

  if(!data.length) {
    if(clearFilters) {
      return <ClearFilters clearFilters={clearFilters} />
    }
    return null;
  }

  return (
    <div>
      {currentTableData.map((item, key) => {

        const { uniqid, is_evm_approved, type, currency, status_details, created_at, crypto_amount, void_details, gateway, total_display, status } = item;

        const isFree = +total_display === 0;

        const s = orderStatus(invoiceTranslate.t)[status || 0] || "";

        return <div className="table-mobile-card" key={key}>

          <div onClick={() => navigate(uniqid)} className="table-mobile-card-header">
            <div className="flex">
              <div className="table-mobile-card-header-icon">
                {type === 'SHOPPING_CART' ?
                  <CartIcon /> :
                  type === 'PRODUCT_SUBSCRIPTION' ?
                    <SubsIcon /> :
                    <ProductIcon />
                }
              </div>

              <div className="table-mobile-card-header-main">
                <a onClick={() => navigate(uniqid)} className="flex">{uniqid}</a>
                <div>{moment(created_at * 1000).format('HH:mm DD MMM')}</div>
              </div>
            </div>
            <div style={{ color: "var(--black5)" }}>
              <ArrowRightIcon />
            </div>
          </div>

          <div className="table-mobile-card-body">
            <div className="table-mobile-card-body-list">
              {skipGateway ? null : <div>
                <div>{t('table.columns.gateway')}</div>
                <div>
                  {(isFree || !gateway) ? " - " : <div className="flex items-center">
                    <img className="mr-2" style={{ width: 15, height: 15 }} src={GATEWAYS_OPTIONS[gateway].imgUrl} alt=""/> {GATEWAYS_OPTIONS[gateway].title}
                  </div>}
                </div>
              </div>}
              <div>
                <div>{t('table.columns.value')}</div>
                <div>
                  {
                    isFree ?
                      <div className="flex flex-col">
                        <span className="flex" />
                        <span className="flex">{t('table.row.free')}</span>
                      </div>
                      :
                      <div className="flex flex-col items-end">
                        <span className="flex mb-1">{FIAT_SYMBOLS[currency]} {total_display}</span>
                        <span className="flex">{crypto_amount}</span>
                      </div>
                  }
                </div>
              </div>
              <div>
                <div>{t('table.columns.status')}</div>
                <div>
                  <div className="flex justify-center gap-2">
                    {status === "VOIDED" && void_details === "PRODUCT_SOLD_OUT" && <span className="badge badge-cancelled">{t('table.row.soldOut')}</span>}
                    {status === "VOIDED" && void_details === "CART_PRODUCTS_SOLD_OUT" && <span className="badge badge-cancelled">{t('table.row.cartSoldOut')}</span>}
                    {status === "COMPLETED" && status_details === "CART_PARTIAL_OUT_OF_STOCK" && <span className="badge badge-cancelled">{t('table.row.partialOut')}</span>}
                    <div className={`badge badge-${(s || "").toLowerCase()}`}>
                      {s}
                    </div>

                    {is_evm_approved ?
                      <Tooltip name={`approved-${uniqid}`} tooltipText={`You have allowed us to automatically use your ${gateway} funds to pay for invoices on recurring bills.`}>
                        <div className="badge badge-confirmation">
                            Allowance
                        </div>
                      </Tooltip>
                      : null}
                  </div>
                </div>
              </div>
              <div>
                <div>{t('table.columns.update')}</div>
                <div>{moment(created_at * 1000).format('HH:mm DD MMM')}</div>
              </div>
            </div>
          </div>

        </div>
      })}

      <Pagination
        currentPage={+currentPage}
        totalCount={data.length}
        pageSize={SIZE}
        onPageChange={setCurrentPage}
      />

    </div>
  );
};

export default MobileProducts;
