import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment, { Moment } from "moment";
import { BootstrapDateRangePicker } from '@sellix/shared/components';
import { getRewardsAnalytics } from "./actions.ts";
import { RewardPayload } from "../../types/types.ts";
import config, { random } from "../../utils/config.tsx";
import { DATE_RANGES } from "./constants.ts";

import Breadcrumbs from "../../components/breadcrumbs";
import Select from "../../components/select";
import Search from "../../components/search";
import Table from "../../components/table";
import MobilePlaceholder from "../subscriptions/mobile-placeholder.tsx";
import { useTranslation } from "react-i18next";
import useMobile from "../../hooks/useMobile.ts";
import RewardValue from "./reward-value";
import MobileRewards from "./mobile-rewards.tsx";

import "./index.css";

interface IGetDataOptions {
  start: Moment
  end: Moment
}

const TYPE_OPTIONS = [
  { value: "ALL", label: "All" },
  { value: "SEND_COUPON_CODE", label: "Coupon", actions: ["SEND_COUPON_CODE"] },
  { value: "SEND_PRODUCT", label: "Free Product", actions: ["SEND_PRODUCT"] },
  { value: "TOP_UP_BALANCE", label: "Top Up Balance", actions: ["TOP_UP_BALANCE_AMOUNT", "TOP_UP_BALANCE_PERCENT"] },
];
const DEFAULT_TYPE = "ALL";
const DEFAULT_DATE_RANGE = "All time";

const Rewards = () => {

  const { t } = useTranslation("translation", { keyPrefix: "customer.rewards" });
  const isMobile = useMobile();
  const [loading, setLoading] = useState(false);
  const [rewardsHistory, setRewardsHistory] = useState<RewardPayload[]>([]);
  const [searchKey, setSearchKey] = useState("");
  const [searchType, setSearchType] = useState(DEFAULT_TYPE);
  const [resetDateRangeKey, setResetDateRangeKey] = useState(0);

  const getData = useCallback(async({ start, end }: IGetDataOptions) => {
    setLoading(true);
    const { status, data, error } = await getRewardsAnalytics(start, end);
    if (status === 200) {
      setRewardsHistory(data.history);
    } else {
      console.log(error);
    }
    setLoading(false);
  }, []);

  const columns = [{
    accessorKey: "clause",
    header: t("table.columns.action"),
    cell: ({ row }: { row: { original: RewardPayload } }) => (config.REWARDS.CLAUSES as Record<string, any>)[row.original.clause]?.title,
  }, {
    accessorKey: "action",
    header: t("table.columns.clause"),
    cell: ({ row }: {row: { original: RewardPayload }}) => <RewardValue reward={row.original}/>,
  }, {
    accessorKey: "created_at",
    header: t("table.columns.date"),
    cell: ({ row }: {row: { original: RewardPayload }}) => moment(row.original.created_at * 1000).utc().format("DD MMM, YYYY"),
  }];

  const placeholder = [{
    accessorKey: "clause",
    header: t("table.columns.action"),
    cell: () => <div className="skeleton skeleton2 " style={{ width: random(100, 140) }}/>
  }, {
    accessorKey: "action",
    header: t("table.columns.clause"),
    cell: () => <div className="skeleton skeleton2" style={{ width: random(60, 60) }}/>
  }, {
    accessorKey: "created_at",
    header: t("table.columns.date"),
    cell: () => <div className="skeleton skeleton2" style={{ width: random(60, 160) }}/>
  }];

  useEffect(() => {
    const [start, end] = DATE_RANGES[DEFAULT_DATE_RANGE];
    getData({ start, end }).catch(console.error);
  }, [getData, resetDateRangeKey]);

  const filterByType = useCallback((rewards: RewardPayload[]): RewardPayload[] => {
    const typeOption = TYPE_OPTIONS.find((option) => option.value === searchType);
    if (!typeOption || typeOption.value === "ALL") {
      return rewards;
    } else {
      return rewards.filter((reward) => (typeOption.actions || []).includes(reward.action));
    }
  }, [searchType]);

  const clearFilters = useCallback(() => {
    setSearchType(DEFAULT_TYPE);
    setSearchKey("");
    setResetDateRangeKey((prevKey) => ++prevKey);
  }, [setSearchType, setSearchKey, setResetDateRangeKey]);

  const rewardsData = useMemo(() => {
    let rewards = rewardsHistory;
    rewards = filterByType(rewards);

    if (searchKey) {
      rewards = rewards.filter((reward) => {
        const { reward_info: { product, product_variant, coupon } } = reward;
        const values = [
          reward.action === "SEND_PRODUCT" ? (product?.title || "") : "",
          reward.action === "SEND_PRODUCT" ?  (product_variant || "") : "",
          reward.action === "SEND_COUPON_CODE" ? (coupon?.code || "") : "",
          ["TOP_UP_BALANCE_AMOUNT", "TOP_UP_BALANCE_PERCENT"].includes(reward.action) ? `${reward.reward_info.total}` : "",
          reward.action_value,
        ];
        for (const value of values) {
          if (value.toString().toLowerCase().includes(searchKey.toLowerCase())) {
            return true;
          }
        }
      });
    }
    return rewards;
  }, [searchKey, rewardsHistory, filterByType]);

  return (
    <div className="license-screen">

      <div className="screen-header items-start pt-0">
        <Breadcrumbs title={t("pageTitle")} breadcrumbs={[]} />

        <div className="screen-filters gap-2">
          <BootstrapDateRangePicker
            key={resetDateRangeKey}
            ranges={DATE_RANGES}
            defaultRangeKey={DEFAULT_DATE_RANGE} onCallback={getData}
          />
          <Select
            size="large"
            value={searchType}
            onChange={e => setSearchType(e.target.value)}
            options={TYPE_OPTIONS}
          />
          <Search onChange={setSearchKey} search={searchKey} />
        </div>
      </div>

      <div className="license-screen-container">
        {isMobile
          ? null
          : loading
            ? <Table data={[{}, {}, {}, {}, {}, {}]} isPlaceholder columns={placeholder} />
            : (
              <Table
                clearFilters={clearFilters}
                cursorPointer
                data={rewardsData}
                columns={columns}
              />
            )
        }

        {isMobile
          ? loading
            ? <MobilePlaceholder data={[{}, {}, {}]} showHeader={false} />
            : (
              <MobileRewards
                data={rewardsData}
                clearFilters={clearFilters}
              />
            )
          : null
        }
      </div>
    </div>
  );
}

export default Rewards;