import customerApi from "../../../utils/customer-api";
import { formData } from "../../../utils/config.tsx";

export type WithdrawFundsRequestTypePayload = {
  invoice_ids: string[]
  type: "WITHDRAWAL" | "TRANSFER"
  customer_note: string
  amount: number
  customer_id: string
  shop_id: string
}

export type FundsRequestPayload = {
  request_id: string[]
  customer_note: string
  customer_id: string
  shop_id: string
}

export const requestWithdrawFunds = async(params: WithdrawFundsRequestTypePayload): Promise<any> => {
  const res = await customerApi.post(`/customer/funds/requests/create`, formData(params));

  if(res.status === 200) {
    return res;
  } else {
    throw res;
  }
}

export const editRequestFundsNote = async(params: FundsRequestPayload): Promise<any> => {
  const res = await customerApi.post(`/customer/funds/requests/edit`, formData(params));

  if(res.status === 200) {
    return res;
  } else {
    throw res;
  }
}
