import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FIAT_SYMBOLS } from "@sellix/shared/configs";
import { Button } from "@sellix/shared/components";
import Table from "../../components/table";
import { useCustomerContext } from "../../provider/CustomerContext";
import Empty from "../../components/empty";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { FundsRequest, RevenueInvoice } from "../../types/types";
import RequestWithdrawTransferModal from "./modals/request-withdraw.tsx";
import InvoicesModal from "./modals/invoices.tsx";
import EditNoteModal from "./modals/edit-note.tsx";
import CommentModal from "./modals/comment.tsx";
import Overview from "./overview.tsx";
import Select from "../../components/select";

import "./index.css";


enum STATUS {
  PENDING = "PENDING",
  PAID = "PAID",
  NO_ACTION = "NO_ACTION",
  DECLINED = "DECLINED",
  TRANSFERRED = "TRANSFERRED",
  ALL = "ALL"
}

const STATUS_OPTIONS = [
  { value: STATUS.ALL, label: 'all' },
  { value: STATUS.PENDING, label: 'pending' },
  { value: STATUS.NO_ACTION, label: 'noAction' },
  { value: STATUS.PAID, label: 'paid' },
  { value: STATUS.DECLINED, label: 'declined' },
  { value: STATUS.TRANSFERRED, label: 'transferred' }
]

const Balances = () => {

  const [requestWithdrawOpen, setRequestWithdrawModal] = useState<"WITHDRAWAL" | "TRANSFER" | null>(null);
  const [editNoteModalOpen, setEditNoteModalOpen] = useState<boolean>(false);
  const [invoicesModalOpen, setInvoicesModalOpen] = useState<boolean>(false);
  const [invoiceCommentModalOpen, setInvoiceCommentModalOpen] = useState<string | boolean>(false);

  const [activeFundsRequest, setActiveFundsRequest] = useState<FundsRequest | null>(null);
  const [activeRevenueInvoice, setActiveRevenueInvoice] = useState<RevenueInvoice | null>(null);


  const [hoveredFundsRequest, setHoveredFundsRequest] = useState<FundsRequest | null>(null);
  const [hoveredRevenueInvoice, setHoveredRevenueInvoice] = useState<RevenueInvoice | null>(null);

  const [searchStatus, setSearchStatus] = useState<STATUS>(STATUS.ALL);

  const { shopInfo, customerInfo, revenueInvoices, fundsRequests } = useCustomerContext();
  const { t } = useTranslation("translation", { keyPrefix: "customer.balances" });

  useEffect(() => {
    try {
      const status = localStorage.getItem(`revenue-invoices-status-${shopInfo?.shop.id}`) as STATUS;
      setSearchStatus(status);
    } catch (error) {
      setSearchStatus(STATUS.ALL);
    }
  }, []);

  console.log(searchStatus)

  if(!customerInfo) {
    return null
  }

  const updateSearchStatus = (value: STATUS) => {
    try {
      localStorage.setItem(`revenue-invoices-status-${shopInfo?.shop.id}`, value);
    } catch (error) {}
    setSearchStatus(value);
  }

  const idleRevenueInvoices = revenueInvoices.filter(({ status }) => status === "NO_ACTION");

  const getType =  ({ row }: CellContext<RevenueInvoice, unknown>) => {

    switch (row.original.type) {
      case "INVOICE":
        return <div className="dashboard-info-product">
          <div>{t("tables.invoices.row.sale")}</div>
          <span style={{ color: "var(--black4)" }}>{row.original.invoice_id}</span>
        </div>
    }
  }

  const getPercent = ({ row }: CellContext<RevenueInvoice, unknown>) => {

    console.log(row.original.shop?.deduct_customer_discount_from_affiliate_revenue)

    return <div className="dashboard-info-percentage">
      {row.original.products.map((p) => {

        let deductCustomerDiscount = false;
        let customerDiscount = 0;
        if (row.original.shop?.deduct_customer_discount_from_affiliate_revenue) {
          deductCustomerDiscount = true;

          customerDiscount = Math.max( // @ts-ignore
            row.original.customer.affiliate_customer_discount_percent,
            row.original.shop.affiliate_customer_discount_percent,
            0
          )
        }

        const affiliateRevenue = Math.max(
          +customerInfo.customer.affiliate_revenue_percent,
          +customerInfo.shop.affiliate_revenue_percent,
          +p.affiliate_revenue_percent
        );

        if(deductCustomerDiscount) { // @ts-ignore
          return (parseInt(affiliateRevenue) - parseInt(customerDiscount)).toFixed(0) + "%"
        } else {
          return affiliateRevenue + "%"
        }
      }).join(", ")}
    </div>
  }

  const columns: ColumnDef<RevenueInvoice>[] = [{
    accessorKey: "type",
    header: t("tables.invoices.columns.type") as string,
    cell: getType
  }, {
    accessorKey: "products.affiliate_revenue_percent",
    header: t("tables.invoices.columns.revenuePercentage") as string,
    cell: getPercent,
    enableSorting: false
  }, {
    accessorKey: "amount",
    header: t("tables.invoices.columns.amount") as string,
    cell: ({ row }: CellContext<RevenueInvoice, unknown>) => <div className="flex">{FIAT_SYMBOLS[row.original.currency]}{row.original.amount}</div>,
    sortingFn: (a, b) => {
      if(parseInt(a.original.amount) > parseInt(b.original.amount)) {
        return 1
      }
      if(parseInt(b.original.amount) > parseInt(a.original.amount)) {
        return -1
      }

      return 0
    }
  }, {
    accessorKey: "created_at",
    header: t("tables.invoices.columns.created") as string,
    cell: ({ row }: CellContext<RevenueInvoice, unknown>) => moment(row.original.created_at * 1000).format("DD MMM YYYY (HH:mm)")
  }, {
    accessorKey: "status",
    header: t("tables.invoices.columns.status") as string,
    cell: ({ row }: CellContext<RevenueInvoice, unknown>) => <div className="flex">
      {
        row.original.status === "PENDING" ? <div className="badge badge-pending">{t("tables.invoices.statuses.pending")}</div> :
          row.original.status === "DECLINED" ? <div className="badge badge-cancelled">{t("tables.invoices.statuses.declined")}</div> :
            row.original.status === "PAID" ? <div className="badge badge-completed">{t("tables.invoices.statuses.paid")}</div> :
              row.original.status === "TRANSFERRED" ? <div className="badge badge-trialing">{t("tables.invoices.statuses.transferred")}</div> :
                <div className="badge badge-confirmation">{t("tables.invoices.statuses.noAction")}</div>
      }
    </div>
  }];

  const columns2: ColumnDef<FundsRequest>[] = [{
    accessorKey: "type",
    header: t("tables.funds.columns.type") as string,
    cell: ({ row }: CellContext<FundsRequest, unknown>) => row.original.type
  }, {
    accessorKey: "amount",
    header: t("tables.funds.columns.amount") as string,
    cell: ({ row }: CellContext<FundsRequest, unknown>) => `$${row.original.amount}`,
    sortingFn: (a, b) => {
      if(parseInt(a.original.amount) > parseInt(b.original.amount)) {
        return 1
      }
      if(parseInt(b.original.amount) > parseInt(a.original.amount)) {
        return -1
      }

      return 0
    }
  }, {
    accessorKey: "created_at",
    header: t("tables.funds.columns.created") as string,
    cell: ({ row }: CellContext<FundsRequest, unknown>) => moment((row.original.updated_at || row.original.created_at)).format("DD MMM YYYY (HH:mm)")
  }, {
    accessorKey: "status",
    header: t("tables.funds.columns.status") as string,
    cell: ({ row }: CellContext<FundsRequest, unknown>) => <div className="flex">
      {
        row.original.status === "PENDING" ?
          <div className="badge badge-pending">{t("tables.funds.statuses.pending")}</div> :
          row.original.status === "COMPLETED" ?
            <div className="badge badge-completed">{t("tables.funds.statuses.completed")}</div> :
            row.original.status === "PARTIAL" ?
              <div className="badge badge-partial">{t("tables.funds.statuses.partial")}</div> :
              <div className="badge badge-confirmation">{t("tables.funds.statuses.noAction")}</div>
      }
    </div>
  }, {
    enableSorting: false,
    accessorKey: "id",
    header: t("tables.funds.columns.action") as string,
    cell: ({ row }: CellContext<FundsRequest, unknown>) => {

      if(row.original.status === "COMPLETED") {
        return <div className="flex gap-2">
          <Button className="whitespace-nowrap" variant="secondary" size="small" onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setActiveFundsRequest(row.original);
            setInvoicesModalOpen(true);
          }}>{t("tables.funds.row.viewInvoices")}</Button>
        </div>
      }

      return <div className="flex gap-2">
        <Button className="whitespace-nowrap" variant="secondary" size="small" onClick={() => {
          setActiveFundsRequest(row.original);
          setInvoicesModalOpen(true);
        }}>{t("tables.funds.row.viewInvoices")}</Button>
        {row.original.type === "WITHDRAWAL" ? <Button className="whitespace-nowrap" variant="secondary" size="small" onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setActiveFundsRequest(row.original);
          setEditNoteModalOpen(true);
        }}>{t("tables.funds.row.editNote")}</Button> : null}
      </div>
    }
  }];


  const filterByStatus = (invoices: RevenueInvoice[]) => {
    if (searchStatus === STATUS.ALL) {
      return invoices;
    } else {
      return invoices.filter(({ status }) => status === searchStatus);
    }
  };

  let data = revenueInvoices;

  data = filterByStatus(data);


  return <div className="dashboard-info">

    <InvoicesModal
      activeFundsRequest={activeFundsRequest}
      revenueInvoices={revenueInvoices}
      isOpen={invoicesModalOpen}
      close={() => {
        setActiveFundsRequest(null);
        setInvoicesModalOpen(false);
      }}
    />

    <RequestWithdrawTransferModal
      revenueInvoices={idleRevenueInvoices}
      isOpen={!!requestWithdrawOpen}
      isWithdrawal={requestWithdrawOpen === "WITHDRAWAL"}
      setWithdrawRequest={setRequestWithdrawModal}
      activeRevenueInvoice={activeRevenueInvoice}
      close={() => {
        setActiveRevenueInvoice(null);
        setRequestWithdrawModal(null);
      }}
    />

    <EditNoteModal
      isOpen={editNoteModalOpen}
      activeFundsRequest={activeFundsRequest}
      close={() => {
        setActiveRevenueInvoice(null);
        setEditNoteModalOpen(false)
      }}
    />


    <CommentModal
      isOpen={invoiceCommentModalOpen}
      invoice={activeRevenueInvoice}
      close={() => {
        setActiveRevenueInvoice(null);
        setInvoiceCommentModalOpen(false)
      }}
    />

    <Overview />

    {revenueInvoices.length ?
      <div className="dashboard-info-table mt-4">
        <span className="flex justify-between items-center">
          <span>
            {t("tables.invoices.title")}
          </span>
          <div className="flex items-center gap-4">
            <Select
              size="large"
              value={searchStatus}
              onChange={e => {
                updateSearchStatus(e.target.value as STATUS)
              }}
              options={STATUS_OPTIONS.map(({ label, value }) => ({
                label: t(`tables.invoices.statuses.${label}`),
                value
              }))}
            />
          </div>
        </span>
        <Table
          pageSize={10}
          data={data}
          columns={columns}
          className="last-right affiliate-invoices-list"
          rowClassName={(row: any) => {
            if (hoveredFundsRequest) {
              return row.original.request_id == hoveredFundsRequest ? "hovered" : "";
            } else {
              return "";
            }
          }}
          onClick={(row) => {
            if(row.status === "NO_ACTION") {
              setRequestWithdrawModal("WITHDRAWAL");
              setActiveRevenueInvoice(row);
            } else if (row.status !== "PENDING") {
              setInvoiceCommentModalOpen(row.invoice_id);
              setActiveRevenueInvoice(row);
            }
          }}
          onMouseEnter={(row: any) => setHoveredRevenueInvoice(row.original.invoice_id)}
          onMouseLeave={() => setHoveredRevenueInvoice(null)}
        />
      </div> :
      <div>
        <Empty isBalances />
      </div>
    }

    {fundsRequests.length ?
      <div className="dashboard-info-table mt-4">
        <span className="flex justify-between items-center">
          <span>
            {t("tables.funds.title")}
          </span>
        </span>
        <Table
          pageSize={10}
          data={fundsRequests}
          columns={columns2}
          className="last-right requests-list"
          rowClassName={(row: any) => {
            if(hoveredRevenueInvoice) {
              return row.original.invoice_ids.includes(hoveredRevenueInvoice) ? "hovered" : "";
            } else {
              return "";
            }
          }}
          onClick={(row) => {
            setActiveFundsRequest(row);
            setInvoicesModalOpen(true);
          }}
          onMouseEnter={(row: any) => setHoveredFundsRequest(row.original.id)}
          onMouseLeave={() => setHoveredFundsRequest(null)}
        />
      </div> :
      <div />
    }


  </div>
}

export default Balances;
