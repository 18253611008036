import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import api from "../../../utils/api";
import { formData } from "../../../utils/config";
import { Shop } from "../../../types/types.ts";

const FRAUD_KEY = "SLLXFS-NJAF982BJAYS872JG";


const useTaxForm = (shopInfo?: Shop) => {

  const [isBusiness, setBusiness] = useState(false);
  const [disableAddress, setDisableAddress] = useState(false);
  const [removeTaxForEU, setRemoveTaxForEU] = useState(false);
  const [taxAmount, setTaxAmount] = useState(false);

  const [cookies] = useCookies(["ipcountry"]);
  const ip = cookies.ipcountry || "";
  const [ipcountry, setIpCountry] = useState(ip);

  const isTaxConfiguration = shopInfo?.tax_configuration === "EU" || shopInfo?.tax_configuration === "CUSTOM";
  const isEU = shopInfo?.tax_configuration === "EU";
  const onlyBusiness = shopInfo?.validation_only_for_companies;
  const requiredTaxFields = !!shopInfo?.display_tax_custom_fields;

  const [taxData, setTaxData] = useState({
    name: '',
    country_code: ipcountry,
    city: '',
    postcode: '',
    address: '',
    vat_number: '',
    surname: '',
    sdi_code: ''
  });


  useEffect(() => {
    const fetchData = async() => {
      try {

        const url = 'https://api-internal.sellix.io/v1/fraud_shield/info';
        const { status, data } = await api.post(url, formData({ key: FRAUD_KEY }));

        if (status === 200 && data?.info?.country_code !== 'N/A') {
          setIpCountry(data?.info?.country_code);
          setTaxData({
            ...taxData,
            country_code: data?.info?.country_code
          })
        }

      } catch (error) {
        console.error('Error IP Country:', error);
      }
    };

    void fetchData();
  }, []);


  useEffect(() => {
    setTaxData({
      ...taxData,
      country_code: ipcountry ? ipcountry : taxData.country_code
    })
  }, [isBusiness]);

  useEffect(() => {

    if(requiredTaxFields && isEU && !isBusiness && !onlyBusiness) {
      setDisableAddress(true);
    } else {
      setDisableAddress(false);
    }

    if(
      isEU && isBusiness &&
            taxData.country_code &&
            shopInfo.tax_configuration_data &&
            shopInfo.tax_configuration_data.find(({ country }) => country === taxData.country_code)
    ) {
      setRemoveTaxForEU(true);
    } else {
      setRemoveTaxForEU(false);
    }

  }, [requiredTaxFields, isTaxConfiguration, isBusiness, ipcountry, shopInfo?.tax_configuration_data, shopInfo?.vat_percentage, taxData.country_code])

  const isTaxFormValid =
        taxData.name &&
        taxData.country_code &&
        taxData.city &&
        taxData.postcode &&
        taxData.address &&
        ((isBusiness && taxData.vat_number) || true);


  useEffect(() => {

    const { tax_configuration, tax_configuration_data, vat_percentage } = shopInfo || {};

    if ((tax_configuration === "EU" || tax_configuration === "CUSTOM") && tax_configuration_data && taxData.country_code) {
      const countryTax = tax_configuration_data.find((t) => t.country === taxData.country_code);

      if (countryTax && (countryTax.tax_percentage || countryTax.tax_percentage === 0)) {
        // @ts-ignore
        setTaxAmount(`${countryTax.tax_percentage}%`);
      }
    } else {
      // @ts-ignore
      setTaxAmount(+vat_percentage ? `${+vat_percentage}%` : '');
    }
  }, [taxData, taxData.country_code]);

  return {
    taxData,
    setTaxData,
    isTaxByCountry: isTaxConfiguration && ipcountry && shopInfo.tax_configuration_data && shopInfo.tax_configuration_data.find(({ country }) => country === taxData.country_code),
    removeTaxForEU,
    requiredTaxFields,
    disableAddress,
    isTaxFormValid,
    onlyBusiness,
    isBusiness,
    setBusiness,
    taxAmount
  };
}

export default useTaxForm;
