import { createContext, useContext } from 'react';
import { AuthContentType } from '../types/types';

const initialValue: AuthContentType = {
  isLoggedIn: false,
  setLoggedIn: () => {},
  customerProfile: null,
  handleLogout: (): void => {},
  shopId: null,
  isThemeReady: false,
  shopInfo: null,
  customTheme: false,
  customerPortalDomain: "",
  businessName: "",
  loading: false,
  isCustom: false,

  hideCustomerAffiliate: false,
  hideCustomerRewards: false,
  hideCustomerSubscriptions: false,
  hideCustomerLicenses: false,
  hideCustomerGaming: false,
  hideCustomerBalances: false,
  customerMainPage: false,
  isExpiredSubscription: false,
  getShopInfo: () => {}
}

const AuthContext = createContext(initialValue);

export const useAuthContext = () => useContext(AuthContext) as AuthContentType;

export default AuthContext;
