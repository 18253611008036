import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Subscription } from "../../../../../types/types";

import "./index.css";

const InvoiceInfo = ({ tick, bill }: { tick?: number, bill: Subscription }) => {

  const {
    type,
    customer_id,
    product_title,
    product_plan_title,
    created_at,
    canceled_at,
    product_id,
    current_period_end,
    renewal_invoice_created,
  } = bill;

  const { t } = useTranslation('translation', { keyPrefix: "customer.subscriptions.details.overview" });

  return <div className="info-table with-shadow">

    <div className="info-table-header">{t('title')}</div>

    <div className="info-table-body">

      <div>
        <span>{t('product')}</span>
        <span>
          <a rel="noreferrer" href={`/product/${product_id}`} target="_blank">{product_title}</a>
        </span>
      </div>

      {type === "v2" && (
        <div>
          <span>{t('productPlan')}</span>
          <span>{product_plan_title}</span>
        </div>
      )}

      <div>
        <span>{t('paid')}</span>
        <span>{tick}</span>
      </div>

      {renewal_invoice_created ? <div>
        <span>{t('renewal')}</span>
        <span>{renewal_invoice_created}</span>
      </div> : null}

      <div>
        <span>{t('created')}</span>
        <span>{moment(created_at * 1000).format('DD, MMM YYYY HH:mm')}</span>
      </div>

      {current_period_end ? <div>
        <span>{t('currentPeriodEnd')}</span>
        <span>{moment(current_period_end * 1000).format('DD, MMM YYYY HH:mm')}</span>
      </div> : null}

      {canceled_at ? <div>
        <span>{t('cancelled')}</span>
        <span>{moment(canceled_at * 1000).format('DD, MMM YYYY HH:mm')}</span>
      </div> : null}

      <div>
        <span>{t('id')}</span>
        <span style={{ wordBreak: "break-word" }}>{customer_id}</span>
      </div>
    </div>

  </div>
}

export default InvoiceInfo;
