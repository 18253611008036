import customerApi from "../../utils/customer-api";
import { Response, LicenseWrapper } from "../../types/types";


export const getLicenses = async(): Promise<Response<LicenseWrapper>> => {
  const res = await customerApi.get<never, Response<LicenseWrapper>>(`customer/products/licensing`);

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }

}

